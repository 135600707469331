import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "accounting-tab" }
const _hoisted_2 = { class: "grid m-0 justify-content-center" }
const _hoisted_3 = { class: "flex col-12 justify-content-end align-items-center my-0 py-0" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "sales-tab p-0" }
const _hoisted_6 = { class: "grid align-content-center" }
const _hoisted_7 = { class: "col-12 text-center p-0 text-sm h-1rem" }
const _hoisted_8 = { class: "col-12 text-center p-0 text-sm h-1rem" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_SalesOppsDataWrapper = _resolveComponent("SalesOppsDataWrapper")!
  const _component_Card = _resolveComponent("Card")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_SalesOpportunityForm = _resolveComponent("SalesOpportunityForm")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_TabView, {
        scrollable: true,
        activeIndex: _ctx.index,
        onTabChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.index = $event.index)),
        class: "account-tabview"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, null, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_router_link, {
                  to: _ctx.defaultRoute,
                  class: "line-height-4 accounting-tab-item"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createElementVNode("span", { class: "align-self-center pr-3 pl-0" }, "All Opportunities", -1)
                  ])),
                  _: 1
                }, 8, ["to"])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _withDirectives(_createVNode(_component_Button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isResultsListView = !_ctx.isResultsListView)),
                    class: "split-sales-order-btn",
                    icon: _ctx.isResultsListView ? 'pi pi-th-large' : 'pi pi-list',
                    outlined: ""
                  }, null, 8, ["icon"]), [
                    [_directive_tooltip, 
                _ctx.isResultsListView ? 'Kanban Table View' : 'List Table View'
              ]
                  ]),
                  _withDirectives(_createVNode(_component_Button, {
                    class: "split-sales-order-btn",
                    icon: "pi pi-filter-slash",
                    outlined: "",
                    onClick: _ctx.clearFilters
                  }, null, 8, ["onClick"]), [
                    [_directive_tooltip, 'Clear All Filters']
                  ]),
                  _createVNode(_component_Button, {
                    class: "split-sales-order-btn",
                    label: "Print/Export",
                    icon: "pi pi-print",
                    onClick: _ctx.printExportData
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_Button, {
                    "data-test": "newSalesOpps",
                    label: "New Opportunity",
                    icon: "pi pi-plus",
                    onClick: _ctx.handleNewSalesOpp
                  }, null, 8, ["onClick"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Card, null, {
                    content: _withCtx(() => [
                      _createVNode(_component_SalesOppsDataWrapper, {
                        ref: "salesOppsTable",
                        isResultView: _ctx.isResultsListView,
                        onLastUpdated: _ctx.isDataUpdated,
                        onRowClick: _ctx.handleRowClick,
                        onUpdateBadge: _cache[1] || (_cache[1] = 
                    (field, value) =>
                      _ctx.$emit('update-badge', value.toString())
                  ),
                        maxExportRows: 1000,
                        customer: _ctx.customer
                      }, null, 8, ["isResultView", "onLastUpdated", "onRowClick", "customer"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOpportunities, (opp, oppIndex) => {
            return (_openBlock(), _createBlock(_component_TabPanel, {
              key: opp.record.id,
              class: "pt-0"
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_router_link, {
                    to: _ctx.defaultRoute,
                    class: "line-height-4 sales-tab-item p-0 m-0"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(opp.record.id.startsWith("New")
                      ? "New Opportunity "
                      : "Opportunity "), 1),
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.formatTabId(opp.record)) + " " + _toDisplayString(_ctx.opportunityChanges[opp.record.id] ? "" : "*"), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["to"]),
                  _createVNode(_component_Button, {
                    icon: "pi pi-times",
                    rounded: "",
                    class: "p-button-text p-0 m-0",
                    title: `Close Sale Opportunity`,
                    onClick: _withModifiers(($event: any) => (_ctx.handleRemoveSaleOpp(oppIndex)), ["stop"])
                  }, null, 8, ["onClick"])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_SalesOpportunityForm, {
                  onOpportunitySaved: ($event: any) => (_ctx.handleOpportunity($event, oppIndex)),
                  onOpportunityChanged: ($event: any) => (
            _ctx.handleOpportunityChanges($event, opp.old_record)
          ),
                  saleOppProp: opp.record,
                  "old-record": opp.old_record,
                  "disable-save": _ctx.opportunityChanges[opp.record.id],
                  customerProp: _ctx.customer
                }, null, 8, ["onOpportunitySaved", "onOpportunityChanged", "saleOppProp", "old-record", "disable-save", "customerProp"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["activeIndex"])
    ]),
    _createVNode(_component_Dialog, {
      header: "Delete Opportunity",
      visible: _ctx.indexToDelete !== -1,
      modal: "",
      closable: false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Yes",
          onClick: _cache[3] || (_cache[3] = ($event: any) => {
          _ctx.handleRemoveIndex(_ctx.indexToDelete);
          _ctx.indexToDelete = -1;
        })
        }),
        _createVNode(_component_Button, {
          label: "No",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.indexToDelete = -1))
        })
      ]),
      default: _withCtx(() => [
        _cache[6] || (_cache[6] = _createTextVNode(" Unsaved changes detected. Are you sure you want to delete this opportunity? "))
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}