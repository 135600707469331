import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12 mt-0 mr-0 ml-0 surface-100 flex justify-content-between flex-wrap" }
const _hoisted_2 = { class: "my-2" }
const _hoisted_3 = { class: "pos-orders-table-container col-12 p-0 m-0" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "mt-0" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_Button = _resolveComponent("Button")!
  const _component_LookupDataTable = _resolveComponent("LookupDataTable")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_MultiselectWithButton = _resolveComponent("MultiselectWithButton")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_EmailFileDialog = _resolveComponent("EmailFileDialog")!
  const _component_SalesDialog = _resolveComponent("SalesDialog")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_PrintPickTicket = _resolveComponent("PrintPickTicket")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Search, {
          ref: "search_order",
          label: "name",
          file: "SO",
          currentView: "Pos",
          placeholder: "Search for Orders",
          leftIcon: true,
          scannerMode: true,
          class: "large-search-input",
          searchFunction: _ctx.lookupSalesOrder
        }, null, 8, ["searchFunction"])
      ]),
      _createVNode(_component_Button, {
        class: "ml-1 my-2",
        label: "Load All Orders",
        icon: "pi pi-search",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fetchOrdersData(false)))
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          (_ctx.getPosCustomerOrdersLookup)
            ? (_openBlock(), _createBlock(_component_LookupDataTable, {
                key: 0,
                ref: "lookupDataTable",
                lookup: _ctx.getLookup,
                params: _ctx.getLookupParams,
                lazyLoadPagination: true,
                manualLoad: _ctx.manuallyLoadOrders,
                tableClass: "p-datatable-sm w-12 text-sm ",
                onRowClick: _ctx.rowClick,
                getFullRecords: ""
              }, {
                actions: _withCtx(({ data }) => [
                  _withDirectives((_openBlock(), _createBlock(_component_Button, {
                    class: "m-1 download-button",
                    id: "downloadButton",
                    title: "Download",
                    onClick: ($event: any) => (_ctx.getSalesOrderPDF(data.so_id)),
                    style: { padding: '11px' }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("i", {
                        class: _normalizeClass(_ctx.salePDFIcon(data.so_id))
                      }, null, 2)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])), [
                    [
                      _directive_tooltip,
                      'Generate PDF Sales Order ' + data.so_id,
                      void 0,
                      { left: true }
                    ]
                  ]),
                  _withDirectives(_createVNode(_component_Button, {
                    class: "m-1",
                    id: "emaildButton",
                    title: "Email",
                    onClick: ($event: any) => (_ctx.handleEmailPDF(data.so_id)),
                    icon: "pi pi-at"
                  }, null, 8, ["onClick"]), [
                    [
                      _directive_tooltip,
                      'Send Email Sales Order ' + data.so_id,
                      void 0,
                      { left: true }
                    ]
                  ]),
                  (_ctx.showPickTicketPrint)
                    ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                        key: 0,
                        class: "m-1",
                        id: "pickTicketButton",
                        title: "Pick Ticket",
                        onClick: ($event: any) => (_ctx.showPickTicketDialog(data)),
                        icon: "pi pi-print"
                      }, null, 8, ["onClick"])), [
                        [
                          _directive_tooltip,
                          'Print Pick Ticket ' + data.so_id,
                          void 0,
                          { left: true }
                        ]
                      ])
                    : _createCommentVNode("", true),
                  (_ctx.getIsSmsEnabled)
                    ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                        key: 1,
                        class: "m-1",
                        id: "smsButton",
                        title: "SMS",
                        onClick: ($event: any) => (_ctx.sendSMSOrder(data.so_id)),
                        icon: "pi pi-comments"
                      }, null, 8, ["onClick"])), [
                        [
                          _directive_tooltip,
                          'Send a sms for ' + data.so_id,
                          void 0,
                          { left: true }
                        ]
                      ])
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["lookup", "params", "manualLoad", "onRowClick"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_LoadingSpinner, { loading: _ctx.isLoadingSales }, null, 8, ["loading"]),
                _createElementVNode("div", _hoisted_5, [
                  (!_ctx.isLoadingSales)
                    ? (_openBlock(), _createBlock(_component_DataTable, {
                        key: 0,
                        value: this.salesItems,
                        breakpoint: "1023px",
                        dataKey: "so_id",
                        selectionMode: "single",
                        responsiveLayout: "stack",
                        filterDisplay: "menu",
                        class: "p-datatable-sm",
                        first: _ctx.first,
                        "onUpdate:first": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.first) = $event)),
                        filters: _ctx.filters,
                        "onUpdate:filters": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filters) = $event)),
                        rows: _ctx.rows,
                        paginator: true,
                        sortField: _ctx.sortField,
                        sortOrder: _ctx.sortOrder,
                        rowClass: _ctx.isSelectedRow,
                        rowsPerPageOptions: [10, 25, 50],
                        onSort: _cache[7] || (_cache[7] = ($event: any) => (_ctx.sortData($event))),
                        onPage: _ctx.pageClick,
                        onRowClick: _ctx.rowClick
                      }, {
                        empty: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.emptyTableLabel), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            field: "so_id",
                            header: "ID",
                            showAddButton: false,
                            showFilterMatchModes: false,
                            showFilterOperator: false,
                            sortable: ""
                          }, {
                            body: _withCtx(({ data }) => [
                              _createTextVNode(_toDisplayString(data.so_id), 1)
                            ]),
                            filter: _withCtx(({ filterModel }) => [
                              _createVNode(_component_InputText, {
                                type: "text",
                                modelValue: filterModel.value,
                                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                                class: "p-column-filter",
                                placeholder: "Search by Id"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Column, { header: "Reps" }, {
                            body: _withCtx((slotProps) => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.rep_items, (rep, index) => {
                                return (_openBlock(), _createElementBlock("span", { key: index }, [
                                  _createTextVNode(_toDisplayString(rep.rep_name) + " ", 1),
                                  (index < slotProps.data.rep_items.length - 1)
                                    ? (_openBlock(), _createElementBlock("br", _hoisted_6))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Column, {
                            field: "type",
                            header: "Type",
                            sortable: ""
                          }),
                          _createVNode(_component_Column, {
                            field: "formatted_date",
                            header: "Order Date",
                            filterField: "formatted_date",
                            showAddButton: false,
                            showFilterMatchModes: true,
                            showFilterOperator: false,
                            sortable: ""
                          }, {
                            body: _withCtx(({ data }) => [
                              _createTextVNode(_toDisplayString(data.date), 1)
                            ]),
                            filter: _withCtx(({ filterModel }) => [
                              _createVNode(_component_Calendar, {
                                modelValue: filterModel.value,
                                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                                dateFormat: "mm-dd-yy",
                                class: "p-column-filter",
                                selectionMode: "range",
                                placeholder: "Select Date Range"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Column, {
                            field: "status",
                            header: "Status",
                            showAddButton: false,
                            showFilterMatchModes: false,
                            showFilterOperator: false
                          }, {
                            filter: _withCtx(() => [
                              _createVNode(_component_MultiselectWithButton, {
                                value: _ctx.selectedFilterStatus,
                                options: _ctx.status,
                                optionLabel: "status",
                                optionValue: "initial",
                                dataKey: "initial",
                                placeHolder: "Filter by Status",
                                filter: true,
                                filterMatchMode: "startsWith",
                                style: { width: '200px' },
                                btnLabel: "Apply",
                                onBtnCallback: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchOrdersData(false))),
                                onUpdateFilterValue: _cache[2] || (_cache[2] = 
                      (filterValue) => (this.selectedFilterStatus = filterValue)
                    )
                              }, null, 8, ["value", "options"])
                            ]),
                            filterclear: _withCtx(() => [
                              _createVNode(_component_Button, {
                                type: "button",
                                label: "Clear",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clearStatusSearchBar()))
                              })
                            ]),
                            filterapply: _withCtx(() => [
                              _createVNode(_component_Button, {
                                type: "button",
                                label: "Apply",
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.fetchOrdersData(false)))
                              })
                            ]),
                            body: _withCtx((slotProps) => [
                              _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("span", {
                                  class: _normalizeClass({ 'status-column': slotProps.data.status })
                                }, _toDisplayString(_ctx.statusLabel[slotProps.data.status]), 3)
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Column, {
                            field: "po_number",
                            header: "Purchase Order #",
                            sortable: "",
                            bodyStyle: "text-align: right"
                          }),
                          _createVNode(_component_Column, {
                            field: "order_amount",
                            header: "Order Total",
                            bodyStyle: "text-align: right"
                          }),
                          (!this.hideOrderButtons)
                            ? (_openBlock(), _createBlock(_component_Column, { key: 0 }, {
                                body: _withCtx(({ data }) => [
                                  _withDirectives((_openBlock(), _createBlock(_component_Button, {
                                    class: "m-1 download-button",
                                    id: "downloadButton",
                                    title: "Download",
                                    onClick: ($event: any) => (_ctx.getSalesOrderPDF(data.so_id)),
                                    style: { padding: '11px' }
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("i", {
                                        class: _normalizeClass(_ctx.salePDFIcon(data.so_id))
                                      }, null, 2)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])), [
                                    [
                                      _directive_tooltip,
                                      'Generate PDF Sales Order ' + data.so_id,
                                      void 0,
                                      { left: true }
                                    ]
                                  ]),
                                  _withDirectives(_createVNode(_component_Button, {
                                    class: "m-1",
                                    id: "emaildButton",
                                    title: "Email",
                                    onClick: ($event: any) => (_ctx.handleEmailPDF(data.so_id)),
                                    icon: "pi pi-at"
                                  }, null, 8, ["onClick"]), [
                                    [
                                      _directive_tooltip,
                                      'Send Email Sales Order ' + data.so_id,
                                      void 0,
                                      { left: true }
                                    ]
                                  ]),
                                  (_ctx.showPickTicketPrint)
                                    ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                                        key: 0,
                                        class: "m-1",
                                        id: "pickTicketButton",
                                        title: "Pick Ticket",
                                        onClick: ($event: any) => (_ctx.showPickTicketDialog(data)),
                                        icon: "pi pi-print"
                                      }, null, 8, ["onClick"])), [
                                        [
                                          _directive_tooltip,
                                          'Print Pick Ticket ' + data.so_id,
                                          void 0,
                                          { left: true }
                                        ]
                                      ])
                                    : _createCommentVNode("", true),
                                  (_ctx.getIsSmsEnabled)
                                    ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                                        key: 1,
                                        class: "m-1",
                                        id: "smsButton",
                                        title: "SMS",
                                        onClick: ($event: any) => (_ctx.sendSMSOrder(data.so_id)),
                                        icon: "pi pi-comments"
                                      }, null, 8, ["onClick"])), [
                                        [
                                          _directive_tooltip,
                                          'Send a sms for ' + data.so_id,
                                          void 0,
                                          { left: true }
                                        ]
                                      ])
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["value", "first", "filters", "rows", "sortField", "sortOrder", "rowClass", "onPage", "onRowClick"]))
                    : _createCommentVNode("", true)
                ])
              ]))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_EmailFileDialog, {
      show: _ctx.showEmailPDFDialog,
      header: 'Email Order #' + _ctx.orderIdToEmail,
      onHide: _cache[8] || (_cache[8] = 
      (isHidden) => {
        _ctx.showEmailPDFDialog = isHidden;
      }
    ),
      fileId: _ctx.orderIdToEmail,
      fileName: _ctx.orderIdToEmail,
      contacts: _ctx.getCustomer?.contact_id_items,
      onOnEmail: _ctx.sendEmail,
      onOnAddressAdded: _ctx.handleAddressAdded
    }, null, 8, ["show", "header", "fileId", "fileName", "contacts", "onOnEmail", "onOnAddressAdded"]),
    _createVNode(_component_SalesDialog, {
      order: _ctx.salesOrder,
      show: _ctx.showSalesDialog,
      readOnly: true,
      orderId: _ctx.salesOrder.so_id,
      showLoadExistingQuoteBtn: false,
      onHide: _cache[9] || (_cache[9] = 
      (isHidden) => {
        _ctx.showSalesDialog = isHidden;
      }
    )
    }, null, 8, ["order", "show", "orderId"]),
    _createVNode(_component_Dialog, {
      header: "Sales Actions",
      visible: _ctx.visibleSalesActions,
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.visibleSalesActions) = $event)),
      modal: true,
      draggable: false
    }, {
      footer: _withCtx(() => [
        (
          _ctx.salesOrder.lis_items && _ctx.salesOrder.status != 'C' && _ctx.posShowEditOrder
        )
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: "Edit Order",
              icon: "pi pi-pencil",
              class: "p-button-text",
              onClick: _ctx.addOrderToParts
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.salesOrder.lis_items)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              label: "View Order",
              icon: "pi pi-search",
              class: "p-button-text",
              onClick: _ctx.showOrderDialog
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          label: "Include Order",
          icon: "pi pi-dollar",
          class: "p-button-text",
          onClick: _cache[10] || (_cache[10] = ($event: any) => {
          _ctx.addSalesOrderToSummary();
          _ctx.visibleSalesActions = false;
        }),
          disabled: _ctx.disableShipButton
        }, null, 8, ["disabled"]),
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (this.visibleSalesActions = false))
        })
      ]),
      default: _withCtx(() => [
        _cache[14] || (_cache[14] = _createElementVNode("div", null, "Which action would you like to perform?", -1))
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_PrintPickTicket, {
      visible: _ctx.showPickTicketPrinterDialog,
      salesOrder: _ctx.salesOrder,
      onHandleClose: _cache[13] || (_cache[13] = ($event: any) => (_ctx.showPickTicketPrinterDialog = false))
    }, null, 8, ["visible", "salesOrder"])
  ], 64))
}