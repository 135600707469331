import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, vShow as _vShow } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "w-full flex justify-content-center align-items-center spinner-wrapper"
}
const _hoisted_2 = { key: 2 }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex justify-content-center align-items-center py-6"
}
const _hoisted_4 = {
  key: 0,
  class: "flex flex-wrap"
}
const _hoisted_5 = {
  class: "p-inputgroup mx-3 w-3",
  style: { minWidth: '350px' }
}
const _hoisted_6 = {
  key: 1,
  class: "w-9"
}
const _hoisted_7 = { class: "p-inputgroup mt-2 px-3 w-4" }
const _hoisted_8 = { class: "pos-wrapper grid m-0 justify-content-center" }
const _hoisted_9 = { class: "categories-container col-12" }
const _hoisted_10 = { class: "register-buttons-wrapper" }
const _hoisted_11 = {
  class: "w-full h-full flex flex-column justify-content-around align-items-start",
  style: {"overflow":"hidden","text-overflow":"ellipsis"}
}
const _hoisted_12 = { class: "font-medium" }
const _hoisted_13 = {
  style: {"text-overflow":"ellipsis","white-space":"nowrap"},
  class: "text-xs"
}
const _hoisted_14 = { class: "w-full flex justify-content-between" }
const _hoisted_15 = {
  key: 0,
  class: "pi pi-check text-green-500 flex align-items-end"
}
const _hoisted_16 = {
  key: 1,
  class: "pi pi-lock text-orange-600 flex align-items-end"
}
const _hoisted_17 = {
  key: 2,
  class: "w-9"
}
const _hoisted_18 = {
  key: 3,
  class: "w-9 py-3"
}
const _hoisted_19 = { class: "p-inputgroup mt-2" }
const _hoisted_20 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '104px' }
}
const _hoisted_21 = { class: "flex justify-content-end align-items-center" }
const _hoisted_22 = { key: 3 }
const _hoisted_23 = { class: "text-lg mb-3 inline-block" }
const _hoisted_24 = { class: "p-inputgroup mt-2" }
const _hoisted_25 = {
  key: 0,
  class: "text-sm balance-invalid"
}
const _hoisted_26 = {
  key: 1,
  class: "text-sm balance-invalid"
}
const _hoisted_27 = {
  key: 2,
  class: "text-sm balance-invalid"
}
const _hoisted_28 = {
  key: 3,
  class: "text-sm help-text"
}
const _hoisted_29 = { class: "p-inputgroup mt-2" }
const _hoisted_30 = { class: "p-inputgroup mt-2" }
const _hoisted_31 = { class: "sm:col-12 md:col-12 lg:col-12 ml-0 p-0 grid pos-content" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = {
  key: 0,
  class: "col-12 p-0 m-0 grid justify-content-between align-items-center"
}
const _hoisted_35 = { class: "menutab-item" }
const _hoisted_36 = { class: "align-content-center flex justify-content-start sm:justify-content-end col-7 sm:col-4 md:col-3 p-0" }
const _hoisted_37 = { key: 1 }
const _hoisted_38 = {
  key: 0,
  class: "side-order col-12 lg:col-4 flex flex-column"
}
const _hoisted_39 = { class: "pos-wrapper p-4 flex flex-column justify-content-between bg-white h-full mt-3 products-order-wrapper" }
const _hoisted_40 = {
  class: "shippment-additional-info pr-3",
  style: { whiteSpace: 'pre-wrap' }
}
const _hoisted_41 = {
  key: 0,
  class: "flex justify-content-between flex-wrap"
}
const _hoisted_42 = { class: "inline my-1 ml-auto" }
const _hoisted_43 = { class: "blue-text font-bold" }
const _hoisted_44 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_45 = { class: "inline my-1 ml-auto" }
const _hoisted_46 = { class: "blue-text font-bold" }
const _hoisted_47 = {
  class: "part-name-label",
  style: {"margin-left":"3px"}
}
const _hoisted_48 = { class: "flex justify-content-between align-items-center pr-3 mb-2" }
const _hoisted_49 = {
  key: 0,
  class: "font-semibold mt-1 mb-1 inline-block"
}
const _hoisted_50 = {
  key: 1,
  class: "font-semibold mt-1 mb-1 inline-block"
}
const _hoisted_51 = {
  key: 2,
  class: "flex"
}
const _hoisted_52 = { key: 3 }
const _hoisted_53 = {
  key: 0,
  class: "flex align-items-center pr-3 mb-2"
}
const _hoisted_54 = { key: 1 }
const _hoisted_55 = { class: "address-label-item mt-1 mb-1 inline-block" }
const _hoisted_56 = { class: "address-label-item mt-1 mb-1 inline-block" }
const _hoisted_57 = {
  key: 2,
  class: "products-list-wrapper pr-3",
  ref: "itemsContainer"
}
const _hoisted_58 = ["id"]
const _hoisted_59 = {
  class: "grid pointer",
  tabindex: "0"
}
const _hoisted_60 = { class: "col-8" }
const _hoisted_61 = { class: "blue-text font-bold product-name-label inline-block" }
const _hoisted_62 = { class: "part-name-label inline-block" }
const _hoisted_63 = { class: "col-4 flex flex-column align-items-end justify-content-between" }
const _hoisted_64 = { class: "" }
const _hoisted_65 = ["onClick"]
const _hoisted_66 = { class: "flex align-items-end" }
const _hoisted_67 = { class: "font-bold ml-1" }
const _hoisted_68 = {
  key: 3,
  class: "products-list-wrapper pr-3",
  ref: "itemsContainer"
}
const _hoisted_69 = ["onClick"]
const _hoisted_70 = { class: "col-8" }
const _hoisted_71 = { class: "blue-text font-bold product-name-label inline-block" }
const _hoisted_72 = {
  key: 0,
  class: "part-name-label inline-block"
}
const _hoisted_73 = { key: 1 }
const _hoisted_74 = {
  key: 2,
  class: "part-name-label inline-block"
}
const _hoisted_75 = {
  key: 3,
  class: "part-name-label inline-block"
}
const _hoisted_76 = { class: "col-4 flex flex-column align-items-end justify-content-between" }
const _hoisted_77 = ["onClick"]
const _hoisted_78 = { key: 1 }
const _hoisted_79 = {
  key: 2,
  class: "part-name-label inline-block"
}
const _hoisted_80 = { class: "flex align-items-end" }
const _hoisted_81 = { class: "font-bold ml-1" }
const _hoisted_82 = { class: "payment-quantities pr-3 pt-2" }
const _hoisted_83 = {
  key: 0,
  class: "shipment-additional-info pt-2"
}
const _hoisted_84 = {
  key: 0,
  class: "font-semibold flex justify-content-between flex-wrap"
}
const _hoisted_85 = { class: "blue-text font-bold my-1 ml-auto" }
const _hoisted_86 = {
  key: 1,
  class: "font-semibold flex justify-content-between flex-wrap"
}
const _hoisted_87 = { class: "blue-text font-bold my-1 ml-auto" }
const _hoisted_88 = {
  key: 2,
  class: "font-semibold flex justify-content-between flex-wrap"
}
const _hoisted_89 = { class: "blue-text font-bold my-1 ml-auto" }
const _hoisted_90 = {
  key: 3,
  class: "font-semibold flex justify-content-between flex-wrap"
}
const _hoisted_91 = { class: "mt-1 mb-1 inline-block" }
const _hoisted_92 = { class: "blue-text font-bold my-1 ml-auto" }
const _hoisted_93 = { class: "mt-1 mb-1 inline-block" }
const _hoisted_94 = {
  class: "blue-text font-bold my-1 ml-auto",
  style: {"white-space":"break-spaces","text-align":"right"}
}
const _hoisted_95 = {
  key: 4,
  class: "font-semibold flex justify-content-between flex-wrap"
}
const _hoisted_96 = { class: "blue-text font-bold my-1 ml-auto" }
const _hoisted_97 = { class: "" }
const _hoisted_98 = {
  key: 0,
  class: "products-list-wrapper"
}
const _hoisted_99 = ["onClick"]
const _hoisted_100 = { class: "col-8 py-0" }
const _hoisted_101 = ["onClick"]
const _hoisted_102 = {
  key: 1,
  class: "blue-text font-bold product-name-label inline-block"
}
const _hoisted_103 = {
  key: 2,
  class: "blue-text font-bold product-name-label inline-block"
}
const _hoisted_104 = {
  key: 3,
  class: "blue-text font-bold product-name-label inline-block"
}
const _hoisted_105 = {
  key: 4,
  class: "blue-text font-bold product-name-label inline-block"
}
const _hoisted_106 = {
  key: 5,
  class: "part-name-label inline-block"
}
const _hoisted_107 = {
  key: 6,
  class: "part-name-label inline-block"
}
const _hoisted_108 = {
  key: 7,
  class: "part-name-label inline-block"
}
const _hoisted_109 = { class: "col-4 p-0 flex flex-column align-items-end justify-content-between" }
const _hoisted_110 = { class: "flex align-items-end" }
const _hoisted_111 = { class: "font-bold ml-1" }
const _hoisted_112 = {
  key: 1,
  class: "grid pt-3"
}
const _hoisted_113 = { class: "col-5 text-right font-bold text-sm" }
const _hoisted_114 = {
  key: 2,
  class: "pt-3 pb-2"
}
const _hoisted_115 = {
  key: 3,
  class: "mt-2"
}
const _hoisted_116 = { class: "font-semibold flex justify-content-between flex-wrap" }
const _hoisted_117 = { class: "blue-text font-bold my-1 ml-auto align-content-center" }
const _hoisted_118 = { key: 8 }
const _hoisted_119 = {
  key: 0,
  class: "pi pi-spin pi-spinner p-text-center"
}
const _hoisted_120 = {
  key: 0,
  class: "pi pi-spin pi-spinner p-text-center"
}
const _hoisted_121 = { class: "block" }
const _hoisted_122 = ["innerHTML"]
const _hoisted_123 = ["onClick"]
const _hoisted_124 = {
  key: 0,
  class: "p-0 m-0"
}
const _hoisted_125 = {
  key: 1,
  class: "p-0 m-0"
}
const _hoisted_126 = { class: "card p-0 m-0" }
const _hoisted_127 = { class: "mb-2" }
const _hoisted_128 = { class: "my-2" }
const _hoisted_129 = { class: "my-2" }
const _hoisted_130 = { class: "mt-2" }
const _hoisted_131 = { class: "p-inputgroup mt-2" }
const _hoisted_132 = { class: "p-inputgroup mt-2" }
const _hoisted_133 = { class: "p-inputgroup mt-2" }
const _hoisted_134 = { class: "p-inputgroup mt-2" }
const _hoisted_135 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Steps = _resolveComponent("Steps")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_AuthenticateDialog = _resolveComponent("AuthenticateDialog")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_PosSOCartDialog = _resolveComponent("PosSOCartDialog")!
  const _component_ShipToAddressesTable = _resolveComponent("ShipToAddressesTable")!
  const _component_OrderLineItems = _resolveComponent("OrderLineItems")!
  const _component_OrderSummaryItemWrapper = _resolveComponent("OrderSummaryItemWrapper")!
  const _component_OrderTotal = _resolveComponent("OrderTotal")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_PosInvoiceCartDialog = _resolveComponent("PosInvoiceCartDialog")!
  const _component_UnsavedOrdersDialog = _resolveComponent("UnsavedOrdersDialog")!
  const _component_MessageBox = _resolveComponent("MessageBox")!
  const _component_AddShippingAddressDialog = _resolveComponent("AddShippingAddressDialog")!
  const _component_Signature = _resolveComponent("Signature")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      !this.isRegisterMode ||
      (_ctx.pos.register !== null &&
        _ctx.pos.register !== '' &&
        _ctx.pos.register.status !== 'Closed' &&
        !_ctx.displayDataModal &&
        this.$route.fullPath !== '/pos/customers')
    )
      ? (_openBlock(), _createBlock(_component_Steps, {
          key: 0,
          class: "custom-steps",
          model: _ctx.computedSteps,
          readonly: false
        }, null, 8, ["model"]))
      : _createCommentVNode("", true),
    (_ctx.isLoadingRegOptions || _ctx.isLoadingRegister || _ctx.checkingPosMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_LoadingSpinner, { loading: "" })
        ]))
      : (
      this.isRegisterMode &&
      !this.checkingRegister &&
      (_ctx.pos.register === null ||
        _ctx.pos.register === '' ||
        _ctx.pos.register.status === 'Closed' ||
        _ctx.displayDataModal)
    )
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (!_ctx.isLoadingRegOptions && !_ctx.isLoadingRegister)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_ctx.isRegisterClosed)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _cache[52] || (_cache[52] = _createElementVNode("small", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '200px' }
                          }, "Open Register Amount", -1)),
                          _createVNode(_component_InputNumber, {
                            modelValue: _ctx.openAmt,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openAmt) = $event)),
                            id: "amount",
                            class: "text-sm",
                            onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.open()), ["enter"])),
                            autofocus: "",
                            maxFractionDigits: 2
                          }, null, 8, ["modelValue"])
                        ]),
                        _createVNode(_component_Button, {
                          label: "Submit",
                          icon: "pi pi-check",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.open())),
                          style: { width: '104px', height: '40px' }
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
          this.isRegisterMode &&
          (_ctx.pos.register === null || _ctx.pos.register === '') &&
          this.adminAccess
        )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _cache[53] || (_cache[53] = _createElementVNode("div", {
                            class: "p-inputgroup-addon font-semibold general-label-color",
                            style: { width: '95px' }
                          }, [
                            _createElementVNode("span", { class: "flex justify-content-between align-items-center" }, [
                              _createElementVNode("small", null, "Register"),
                              _createElementVNode("i", { class: "pi pi-filter ml-auto" })
                            ])
                          ], -1)),
                          _createVNode(_component_InputText, {
                            modelValue: _ctx.reg,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.reg) = $event)),
                            id: "reg",
                            class: "text-sm",
                            placeHolder: "Filter By Register Id",
                            onKeyup: _ctx.onFilterRegOptionsList
                          }, null, 8, ["modelValue", "onKeyup"])
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredRegOptions, (data) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: data.reg_id,
                                  class: "bottom-button h-6rem"
                                }, [
                                  _createVNode(_component_Button, {
                                    class: "flex justify-content-between text-lg",
                                    onClick: ($event: any) => (_ctx.onRegisterClick(data.reg_id))
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_11, [
                                        _createElementVNode("span", _hoisted_12, _toDisplayString(data.reg_id), 1),
                                        _createElementVNode("span", _hoisted_13, _toDisplayString(data.hardware_name), 1),
                                        _createElementVNode("div", _hoisted_14, [
                                          _createElementVNode("span", null, _toDisplayString(data.status), 1),
                                          (data.status === 'Open')
                                            ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                                            : (_openBlock(), _createElementBlock("i", _hoisted_16))
                                        ])
                                      ])
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]))
                              }), 128))
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (
          this.isRegisterMode &&
          (_ctx.pos.register === null || _ctx.pos.register === '') &&
          !this.adminAccess
        )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createVNode(_component_AuthenticateDialog, {
                          title: "Register Configuration - Additional Access Required",
                          visible: _ctx.showAdminLogin,
                          requireAdminAccess: true,
                          onOnAuthenticate: _cache[4] || (_cache[4] = ($event: any) => {
            _ctx.showAdminLogin = false;
            this.adminAccess = true;
          })
                        }, null, 8, ["visible"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.displayDataModal)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transaction.datafields, (field) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: field.df_code
                          }, [
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("small", _hoisted_20, _toDisplayString(field.prompt), 1),
                              _createVNode(_component_InputText, {
                                id: "df_code",
                                class: "text-sm",
                                modelValue: _ctx.modalValues[field.df_code],
                                "onUpdate:modelValue": ($event: any) => ((_ctx.modalValues[field.df_code]) = $event)
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ])
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_21, [
                    (_ctx.displayDataModal)
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          label: "Submit",
                          icon: "pi pi-check",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.submitTrans())),
                          class: "ml-3"
                        }))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createVNode(_component_Dialog, {
              visible: _ctx.showAmountModal,
              "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showAmountModal) = $event)),
              modal: ""
            }, {
              header: _withCtx(() => _cache[54] || (_cache[54] = [
                _createElementVNode("span", { class: "text-xl font-semibold" }, "Adjust Amount to Process", -1)
              ])),
              footer: _withCtx(() => [
                _createVNode(_component_Button, {
                  label: "Submit",
                  icon: "pi pi-check",
                  disabled: 
            _ctx.v$.newInvAmt.minValue.$invalid ||
            _ctx.v$.newInvAmt.maxValue.$invalid ||
            _ctx.v$.newInvAmt.required.$invalid
          ,
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateAmount()))
                }, null, 8, ["disabled"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_23, "Invoice #" + _toDisplayString(this.updatingAr.ar_id), 1),
                _createElementVNode("div", _hoisted_24, [
                  _cache[55] || (_cache[55] = _createElementVNode("small", {
                    class: "p-inputgroup-addon font-semibold general-label-color",
                    style: { width: '104px' }
                  }, "New Amount", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.newInvAmt,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newInvAmt) = $event)),
                    id: "reg",
                    class: _normalizeClass(["text-sm", {
            'p-invalid':
              _ctx.v$.newInvAmt.minValue.$invalid ||
              _ctx.v$.newInvAmt.maxValue.$invalid ||
              _ctx.v$.newInvAmt.required.$invalid,
          }]),
                    onKeyup: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.updateAmount()), ["enter"]))
                  }, null, 8, ["modelValue", "class"])
                ]),
                (_ctx.v$.newInvAmt.minValue.$invalid)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_ctx.v$.newInvAmt.minValue.$message), 1))
                  : (_ctx.v$.newInvAmt.maxValue.$invalid)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.v$.newInvAmt.maxValue.$message), 1))
                    : (_ctx.v$.newInvAmt.required.$invalid)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_ctx.v$.newInvAmt.required.$message), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_28, "Invoice Amount: $" + _toDisplayString(this.updatingAr.original_balance || this.updatingAr.balance), 1))
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_Dialog, {
              visible: _ctx.showTenderAmountModal,
              "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.showTenderAmountModal) = $event)),
              modal: ""
            }, {
              header: _withCtx(() => _cache[56] || (_cache[56] = [
                _createTextVNode(" Update Amount Charged ")
              ])),
              footer: _withCtx(() => [
                _createVNode(_component_Button, {
                  label: "Submit",
                  icon: "pi pi-check",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.updateTenderAmount()))
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_29, [
                  _cache[57] || (_cache[57] = _createElementVNode("small", {
                    class: "p-inputgroup-addon font-semibold general-label-color",
                    style: { width: '104px' }
                  }, "New Amount", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.newTndrAmt,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.newTndrAmt) = $event)),
                    id: "reg",
                    class: "text-sm",
                    onKeyup: _cache[11] || (_cache[11] = _withKeys(($event: any) => (_ctx.updateTenderAmount()), ["enter"]))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            }, 8, ["visible"]),
            _createVNode(_component_Dialog, {
              visible: _ctx.showNoteModal,
              "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.showNoteModal) = $event)),
              modal: ""
            }, {
              header: _withCtx(() => _cache[58] || (_cache[58] = [
                _createTextVNode(" Complete Payout ")
              ])),
              footer: _withCtx(() => [
                _createVNode(_component_Button, {
                  label: "Submit",
                  icon: "pi pi-check",
                  onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.addPayNote()))
                })
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_30, [
                  _cache[59] || (_cache[59] = _createElementVNode("small", {
                    class: "p-inputgroup-addon font-semibold general-label-color",
                    style: { width: '104px' }
                  }, "Note", -1)),
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.payNote,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.payNote) = $event)),
                    id: "reg",
                    class: "text-sm",
                    onKeyup: _cache[15] || (_cache[15] = _withKeys(($event: any) => (_ctx.addPayNote()), ["enter"]))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            }, 8, ["visible"]),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", {
                class: _normalizeClass(["col-12 lg:col-8", {
          'lg:col-12':
            _ctx.$route.fullPath === '/pos/order-created' ||
            _ctx.$route.fullPath === '/pos/customers',
        }])
              }, [
                (
            this.$route.fullPath === '/pos/customers' ||
            this.$route.fullPath === '/pos/confirmation' ||
            this.$route.fullPath === '/pos/ship' ||
            this.$route.fullPath === '/pos/payment'
          )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                      _createVNode(_component_router_view, {
                        orderTotal: () => _ctx.total,
                        onOnShowKeepCustomerDialog: _ctx.handleShowKeepCustomerDialog
                      }, null, 8, ["orderTotal", "onOnShowKeepCustomerDialog"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_33, [
                      _createVNode(_component_Dialog, {
                        visible: _ctx.currentSo !== ''
                      }, {
                        footer: _withCtx(() => [
                          _createVNode(_component_Button, {
                            label: "'Yes'",
                            icon: "pi pi-check",
                            onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.confirmCfee()))
                          }),
                          _createVNode(_component_Button, {
                            label: "'No'",
                            icon: "pi pi-times",
                            onClick: _cache[19] || (_cache[19] = ($event: any) => {
                  _ctx.cfeeResp = 'nocc';
                  _ctx.currentSo = '';
                })
                          })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("span", null, "Will Order #" + _toDisplayString(this.currentSo) + " be paid with a Credit Card?", 1)
                        ]),
                        _: 1
                      }, 8, ["visible"]),
                      _createVNode(_component_ConfirmDialog),
                      (
              _ctx.pos.customer &&
              this.$route.fullPath !== '/pos/payment' &&
              this.$route.fullPath !== '/pos/order-created'
            )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                            _createVNode(_component_TabView, {
                              scrollable: "",
                              class: _normalizeClass(["customers-menu-tabview sales-menu-tabview tabview-panels-hidden col-12 sm:col-7 md:col-8 px-0 pr-6", { 'opened-sidebar': !_ctx.isSideBarCollapsed }]),
                              style: { minWidth: '225px !important' },
                              activeIndex: _ctx.active
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedItems, (tab) => {
                                  return (_openBlock(), _createBlock(_component_TabPanel, {
                                    key: tab.label
                                  }, {
                                    header: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_35, [
                                        _createVNode(_component_router_link, {
                                          to: tab.to,
                                          class: "menutab-item-link"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_Button, {
                                              label: tab.label,
                                              class: "menutab-button",
                                              onClick: ($event: any) => (_ctx.changeTab(tab.to))
                                            }, null, 8, ["label", "onClick"])
                                          ]),
                                          _: 2
                                        }, 1032, ["to"])
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["class", "activeIndex"]),
                            _createElementVNode("div", _hoisted_36, [
                              (_ctx.hasPaymentAccess)
                                ? (_openBlock(), _createBlock(_component_SplitButton, {
                                    key: 0,
                                    label: "Other Transactions",
                                    style: {"white-space":"nowrap"},
                                    model: this.otherActions,
                                    onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.handleClick())),
                                    ref: "splitButton"
                                  }, null, 8, ["model"]))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (this.isLoadingInvoice)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                            _createVNode(_component_LoadingSpinner, { loading: "" })
                          ]))
                        : (_openBlock(), _createBlock(_component_router_view, {
                            key: 2,
                            orderTotal: () => _ctx.total,
                            onOnShowKeepCustomerDialog: _ctx.handleShowKeepCustomerDialog,
                            "onUpdate:isOrderHeaderValid": _cache[21] || (_cache[21] = ($event: any) => (_ctx.isOrderHeaderValid = $event)),
                            "onUpdate:companyCode": _cache[22] || (_cache[22] = ($event: any) => (_ctx.changeCompanyCode($event))),
                            onLineItemChanged: _ctx.handleLineItemChanged
                          }, null, 8, ["orderTotal", "onOnShowKeepCustomerDialog", "onLineItemChanged"]))
                    ]))
              ], 2),
              (
          _ctx.$route.fullPath !== '/pos/order-created' &&
          _ctx.$route.fullPath !== '/pos/customers'
        )
                ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_40, [
                          (_ctx.getEntryUser !== null)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_41, [
                                _cache[60] || (_cache[60] = _createElementVNode("p", { class: "font-semibold mt-1 mb-1 inline-block" }, "Entry User:", -1)),
                                _createElementVNode("p", _hoisted_42, [
                                  _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.getEntryUser), 1)
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_44, [
                            _cache[61] || (_cache[61] = _createElementVNode("p", { class: "font-semibold mt-1 mb-1 inline-block" }, "Customer:", -1)),
                            _createElementVNode("p", _hoisted_45, [
                              _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.getCustomer?.name), 1),
                              _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.getCustomer?.custno || _ctx.getCustomer?.cust_id), 1),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.custIdFields, (field) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  class: "part-name-label",
                                  style: {"margin-left":"3px"},
                                  key: field.field_no
                                }, " | " + _toDisplayString(_ctx.getCustomer[field.json_name]), 1))
                              }), 128))
                            ])
                          ]),
                          _createVNode(_component_Divider)
                        ]),
                        _createElementVNode("span", _hoisted_48, [
                          (_ctx.getSalesOrder.so_id && _ctx.showPartsInformation)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_49, " Order #" + _toDisplayString(_ctx.getSalesOrder.so_id), 1))
                            : (_openBlock(), _createElementBlock("p", _hoisted_50, " Current Order: ")),
                          (_ctx.showPartsInformation)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_51, [
                                _createVNode(_component_PosSOCartDialog, {
                                  ref: "cartDialog",
                                  selectedLineItemIndex: _ctx.selectedLineItemIndex,
                                  lineItems: _ctx.selectedLineItems,
                                  onOnRemovePartClick: _ctx.removeEditPart,
                                  onClosePartDialog: _ctx.closePosDialog,
                                  onLineItemChanged: _ctx.handleLineItemChanged
                                }, null, 8, ["selectedLineItemIndex", "lineItems", "onOnRemovePartClick", "onClosePartDialog", "onLineItemChanged"])
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.showInvoiceInformation && _ctx.pos.currentOrder.length > 0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_52, [
                                _createVNode(_component_Button, {
                                  label: _ctx.dynamicClearInvoiceText,
                                  text: "",
                                  onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.showClearInvoicesDialog = true)),
                                  style: { left: '15px' }
                                }, null, 8, ["label"])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        (_ctx.$route.fullPath === '/pos/ship' && _ctx.getCustomer)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_53, [
                              _cache[62] || (_cache[62] = _createElementVNode("p", { class: "address-label-main mt-1 mb-1 inline-block" }, " Ship Address: ", -1)),
                              _createVNode(_component_ShipToAddressesTable, {
                                shipToAddresses: _ctx.addresses,
                                showAllButton: true,
                                salesOrder: _ctx.getOrder[0],
                                customerId: _ctx.getCustomer?.cust_id,
                                onOnClose: _ctx.handleOnClose,
                                onOnRowClick: _ctx.handleRowClick,
                                onOpenAddAddressDialog: _cache[24] || (_cache[24] = ($event: any) => (_ctx.openAddAddressDialog()))
                              }, null, 8, ["shipToAddresses", "salesOrder", "customerId", "onOnClose", "onOnRowClick"])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.$route.fullPath === '/pos/ship' && _ctx.getCustomer)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_54, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOrder[0].ship_address_items, (item, index) => {
                                return (_openBlock(), _createElementBlock("div", { key: index }, [
                                  _createElementVNode("p", _hoisted_55, _toDisplayString(item.ship_address), 1)
                                ]))
                              }), 128)),
                              _createElementVNode("div", null, [
                                _createElementVNode("p", _hoisted_56, _toDisplayString(_ctx.getOrder[0].ship_city) + ", " + _toDisplayString(_ctx.getOrder[0].ship_state) + " " + _toDisplayString(_ctx.getOrder[0].ship_zip), 1)
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_OrderLineItems, {
                          order: this.getSalesOrder,
                          onCartDialogOpened: _ctx.openCartDialog
                        }, null, 8, ["order", "onCartDialogOpened"]),
                        (_ctx.showOrderInformation)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pos.currentOrder, (part, index) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: part.so_id,
                                  id: `order-item-${part.so_id}`
                                }, [
                                  _createElementVNode("div", _hoisted_59, [
                                    _createVNode(_component_OrderSummaryItemWrapper, { part: part }, {
                                      content: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_60, [
                                          _createElementVNode("span", _hoisted_61, "Order #" + _toDisplayString(part.so_id), 1),
                                          _cache[63] || (_cache[63] = _createElementVNode("br", null, null, -1)),
                                          _createElementVNode("span", _hoisted_62, "Order Date: " + _toDisplayString(_ctx.formatDate(part.date)), 1)
                                        ]),
                                        _createElementVNode("div", _hoisted_63, [
                                          _createElementVNode("span", _hoisted_64, _toDisplayString(part.quantity), 1),
                                          (_ctx.mainTabs)
                                            ? (_openBlock(), _createElementBlock("span", {
                                                key: 0,
                                                style: {"z-index":"10"},
                                                onClick: ($event: any) => (_ctx.removePart($event, index))
                                              }, _cache[64] || (_cache[64] = [
                                                _createElementVNode("i", {
                                                  class: "pi pi-trash pointer",
                                                  style: {"color":"red"}
                                                }, null, -1)
                                              ]), 8, _hoisted_65))
                                            : _createCommentVNode("", true),
                                          _createElementVNode("div", _hoisted_66, [
                                            _createElementVNode("span", _hoisted_67, " $" + _toDisplayString(part.order_amount), 1)
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["part"]),
                                    _createVNode(_component_Divider)
                                  ])
                                ], 8, _hoisted_58))
                              }), 128))
                            ], 512))
                          : _createCommentVNode("", true),
                        (_ctx.showInvoiceInformation)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pos.currentOrder, (part, index) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: part.ar_id
                                }, [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["grid pointer", _ctx.clickable]),
                                    tabindex: "0",
                                    onClick: ($event: any) => (
                    this.$route.fullPath !== '/pos/confirmation' &&
                      _ctx.updateArAmount(part)
                  )
                                  }, [
                                    _createVNode(_component_OrderSummaryItemWrapper, { part: part }, {
                                      content: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_70, [
                                          _createElementVNode("span", _hoisted_71, "Invoice #" + _toDisplayString(part.ar_id), 1),
                                          _cache[65] || (_cache[65] = _createElementVNode("br", null, null, -1)),
                                          (
                            part.so_status !== 'C' &&
                            part.so_total &&
                            parseFloat(part.so_total) >
                              parseFloat(part.balance) &&
                            _ctx.applyCmToOpenOrder
                          )
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_72, " Source Order Total "))
                                            : _createCommentVNode("", true),
                                          (
                            part.so_status !== 'C' &&
                            part.so_total &&
                            parseFloat(part.so_total) >
                              parseFloat(part.balance) &&
                            _ctx.applyCmToOpenOrder
                          )
                                            ? (_openBlock(), _createElementBlock("br", _hoisted_73))
                                            : _createCommentVNode("", true),
                                          (part.due_date)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_74, "Due: " + _toDisplayString(_ctx.formatDate(part.due_date)), 1))
                                            : _createCommentVNode("", true),
                                          (part.pay_note)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_75, _toDisplayString(part.pay_note), 1))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _createElementVNode("div", _hoisted_76, [
                                          (_ctx.mainTabs)
                                            ? (_openBlock(), _createElementBlock("span", {
                                                key: 0,
                                                style: {"z-index":"10"},
                                                onClick: ($event: any) => (_ctx.removePart($event, index))
                                              }, _cache[66] || (_cache[66] = [
                                                _createElementVNode("i", {
                                                  class: "pi pi-trash pointer",
                                                  style: {"color":"red"}
                                                }, null, -1)
                                              ]), 8, _hoisted_77))
                                            : (
                            part.so_status !== 'C' &&
                            part.so_total &&
                            parseFloat(part.so_total) >
                              parseFloat(part.balance) &&
                            _ctx.applyCmToOpenOrder
                          )
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_78, " "))
                                              : _createCommentVNode("", true),
                                          (
                            part.so_status !== 'C' &&
                            part.so_total &&
                            parseFloat(part.so_total) >
                              parseFloat(part.balance) &&
                            _ctx.applyCmToOpenOrder
                          )
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_79, _toDisplayString(_ctx.formatPrice(part.so_total)), 1))
                                            : _createCommentVNode("", true),
                                          _createElementVNode("div", _hoisted_80, [
                                            _createElementVNode("span", _hoisted_81, _toDisplayString(_ctx.formatPrice(part.balance)), 1)
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["part"]),
                                    _createVNode(_component_Divider)
                                  ], 10, _hoisted_69)
                                ]))
                              }), 128))
                            ], 512))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_82, [
                        _createVNode(_component_OrderTotal, {
                          order: _ctx.showPartsInformation ? _ctx.getSalesOrder : _ctx.getOrder
                        }, null, 8, ["order"]),
                        (_ctx.showPartsInformation)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_83, [
                              (this.getSalesOrder.po_number)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_84, [
                                    _cache[67] || (_cache[67] = _createElementVNode("p", {
                                      class: "mt-1 mb-1 inline-block",
                                      style: { whiteSpace: 'nowrap' }
                                    }, " PO Number: ", -1)),
                                    _createElementVNode("span", _hoisted_85, _toDisplayString(this.getSalesOrder.po_number), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.getSalesOrder.pos_order_code)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_86, [
                                    _cache[68] || (_cache[68] = _createElementVNode("p", { class: "mt-1 mb-1 inline-block" }, "Order Type:", -1)),
                                    _createElementVNode("span", _hoisted_87, _toDisplayString(_ctx.posDesc), 1)
                                  ]))
                                : (_ctx.getSalesOrder.type)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_88, [
                                      _cache[69] || (_cache[69] = _createElementVNode("p", { class: "mt-1 mb-1 inline-block" }, "Order Type:", -1)),
                                      _createElementVNode("span", _hoisted_89, _toDisplayString(_ctx.orderTypeDesc), 1)
                                    ]))
                                  : _createCommentVNode("", true),
                              (_ctx.getSalesOrder.date)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_90, [
                                    _createElementVNode("p", _hoisted_91, _toDisplayString(_ctx.shipDateLabel) + ":", 1),
                                    _createElementVNode("span", _hoisted_92, _toDisplayString(_ctx.getSalesOrder.date), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayFields, (field) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: field.dict_name,
                                  class: "font-semibold flex justify-content-between flex-wrap"
                                }, [
                                  _createElementVNode("p", _hoisted_93, _toDisplayString(field.display_name) + ":", 1),
                                  _createElementVNode("span", _hoisted_94, _toDisplayString(_ctx.getFieldValue(field)), 1)
                                ]))
                              }), 128)),
                              (
                  this.getSalesOrder.signature_by &&
                  this.getSalesOrder.signature
                )
                                ? (_openBlock(), _createElementBlock("span", _hoisted_95, [
                                    _cache[70] || (_cache[70] = _createElementVNode("p", { class: "mt-1 mb-1 inline-block" }, "Signature Captured:", -1)),
                                    _createElementVNode("span", _hoisted_96, _toDisplayString(_ctx.getSalesOrder.signature_by), 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_97, [
                          (
                  this.$route.fullPath === '/pos/payment' ||
                  this.$route.fullPath === '/pos/confirmation'
                )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_98, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pos.tenders, (tender, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: "flex justify-content-between pointer focus:surface-200",
                                    onClick: ($event: any) => (_ctx.updateTender(tender, index))
                                  }, [
                                    _createElementVNode("div", _hoisted_100, [
                                      (this.$route.fullPath !== '/pos/confirmation')
                                        ? (_openBlock(), _createElementBlock("span", {
                                            key: 0,
                                            onClick: ($event: any) => (this.removeTender($event, index))
                                          }, _cache[71] || (_cache[71] = [
                                            _createElementVNode("i", {
                                              class: "pi pi-trash pointer",
                                              style: {"color":"red"}
                                            }, null, -1),
                                            _createTextVNode("  ")
                                          ]), 8, _hoisted_101))
                                        : _createCommentVNode("", true),
                                      (tender.payment_type === 'CC')
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_102, "Credit Card  "))
                                        : _createCommentVNode("", true),
                                      (tender.payment_type === 'CK')
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_103, "Check  "))
                                        : _createCommentVNode("", true),
                                      (tender.payment_type === 'CA')
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_104, "Cash  "))
                                        : _createCommentVNode("", true),
                                      (tender.payment_type === 'ACH')
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_105, "e-Check  "))
                                        : _createCommentVNode("", true),
                                      (
                        tender.payment_type === 'CC' && !tender.cc_pinpad_mode
                      )
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_106, "Ending in " + _toDisplayString(tender.creditcard_no_items[0].credit_card_id.slice(-4)), 1))
                                        : _createCommentVNode("", true),
                                      (tender.payment_type === 'ACH')
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_107, "Account ending in " + _toDisplayString(tender.bank_account.slice(-4)), 1))
                                        : _createCommentVNode("", true),
                                      (tender.payment_type === 'CK')
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_108, "#" + _toDisplayString(tender.check), 1))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _createElementVNode("div", _hoisted_109, [
                                      _createElementVNode("div", _hoisted_110, [
                                        _createElementVNode("span", _hoisted_111, " $" + _toDisplayString(tender.check_amount), 1)
                                      ])
                                    ])
                                  ], 8, _hoisted_99))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true),
                          (this.$route.fullPath === '/pos/payment')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_112, [
                                _cache[72] || (_cache[72] = _createElementVNode("span", { class: "col-7 text-right remaining-balance-label font-bold" }, "Remaining Balance", -1)),
                                _createElementVNode("span", _hoisted_113, _toDisplayString(_ctx.formatPrice(_ctx.remainingBalance)), 1)
                              ]))
                            : _createCommentVNode("", true),
                          (
                  this.remainingBalance < 0 &&
                  this.$route.fullPath === '/pos/payment' &&
                  this.getRegister?.hide_overage_checkbox !== 'Y' &&
                  this.getRegister?.confirm_change_for_overage !== 'Y' &&
                  !this.isPayout
                )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_114, [
                                _createVNode(_component_Checkbox, {
                                  modelValue: _ctx.oa,
                                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.oa) = $event)),
                                  id: "oa",
                                  inputId: "oa",
                                  binary: true,
                                  disabled: !_ctx.isCash
                                }, null, 8, ["modelValue", "disabled"]),
                                _cache[73] || (_cache[73] = _createElementVNode("label", {
                                  for: "oa",
                                  class: "ml-2 font-medium"
                                }, " Apply Overage to Account ", -1))
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.getShipSignature.signature)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_115, [
                                _createElementVNode("span", _hoisted_116, [
                                  _cache[75] || (_cache[75] = _createElementVNode("span", { class: "flex" }, [
                                    _createElementVNode("span", { class: "mt-1 mb-1 align-content-center" }, " Signature Captured ")
                                  ], -1)),
                                  _createElementVNode("span", _hoisted_117, _toDisplayString(_ctx.getShipSignature.signature_by), 1),
                                  _createElementVNode("span", {
                                    onClick: _cache[26] || (_cache[26] = 
//@ts-ignore
(...args) => (_ctx.clearShipSignature && _ctx.clearShipSignature(...args))),
                                    class: "ml-2 align-self-center",
                                    style: {"display":"flex"}
                                  }, _cache[74] || (_cache[74] = [
                                    _createElementVNode("i", {
                                      class: "pi pi-trash pointer",
                                      style: {"color":"red"}
                                    }, null, -1),
                                    _createTextVNode("  ")
                                  ]))
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.getCustomer && _ctx.$route.name === 'Parts')
                            ? (_openBlock(), _createBlock(_component_Button, {
                                key: 4,
                                onClick: _ctx.submit,
                                class: "w-full h-3rem mt-3",
                                label: _ctx.submitText
                              }, null, 8, ["onClick", "label"]))
                            : _createCommentVNode("", true),
                          (_ctx.showShipSignatureButton)
                            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                                key: 5,
                                class: "w-full h-3rem mt-3",
                                label: 
                  _ctx.getShipSignature.signature
                    ? 'View Signature'
                    : 'Add Signature'
                ,
                                onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.showShipSignatureDialog = true))
                              }, null, 8, ["label"])), [
                                [_directive_tooltip, 
                  _ctx.getShipSignature.signature
                    ? 'View Signature'
                    : 'Add Signature'
                ]
                              ])
                            : _createCommentVNode("", true),
                          (_ctx.showShipOrderButton && _ctx.posAllowPartialShipments)
                            ? (_openBlock(), _createBlock(_component_SplitButton, {
                                key: 6,
                                onClick: _ctx.submit,
                                class: "w-full h-3rem mt-3",
                                label: 
                  this.getOrder.length === 0
                    ? 'Select Order to Continue'
                    : 'Ship Order'
                ,
                                model: _ctx.shipOptions,
                                disabled: _ctx.shipButtonDisabled,
                                icon: _ctx.loadingOrderButton ? 'pi pi-spin pi-spinner' : ''
                              }, null, 8, ["onClick", "label", "model", "disabled", "icon"]))
                            : (_ctx.showShipOrderButton)
                              ? (_openBlock(), _createBlock(_component_Button, {
                                  key: 7,
                                  class: "w-full h-3rem mt-3",
                                  label: 
                  this.getOrder.length === 0 ? 'Select Order' : 'Ship Order'
                ,
                                  onClick: _ctx.submit,
                                  loading: _ctx.loadingOrderButton,
                                  disabled: _ctx.shipButtonDisabled
                                }, null, 8, ["label", "onClick", "loading", "disabled"]))
                              : _createCommentVNode("", true),
                          (_ctx.showInvoicesSplitButton)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_118, [
                                (
                    _ctx.$route.fullPath !== '/pos/invoices' ||
                    (!_ctx.posAllowRefunds && _ctx.hasPaymentAccess)
                  )
                                  ? (_openBlock(), _createBlock(_component_Button, {
                                      key: 0,
                                      style: _normalizeStyle(this.severity),
                                      loading: _ctx.loadingOrderButton,
                                      disabled: _ctx.disabled,
                                      onClick: _ctx.submit,
                                      class: "w-full h-3rem mt-3"
                                    }, {
                                      default: _withCtx(() => [
                                        (_ctx.loadingOrderButton)
                                          ? (_openBlock(), _createElementBlock("i", _hoisted_119))
                                          : _createCommentVNode("", true),
                                        _createTextVNode("   " + _toDisplayString(this.submitText), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["style", "loading", "disabled", "onClick"]))
                                  : (
                    _ctx.hasPaymentAccess || _ctx.$route.fullPath !== '/pos/invoices'
                  )
                                    ? (_openBlock(), _createBlock(_component_SplitButton, {
                                        key: 1,
                                        onClick: _ctx.submit,
                                        model: this.invoiceOptions,
                                        style: _normalizeStyle(this.severity),
                                        loading: _ctx.loadingOrderButton,
                                        disabled: _ctx.disabled,
                                        class: "w-full h-3rem mt-3",
                                        label: this.submitText
                                      }, {
                                        default: _withCtx(() => [
                                          (_ctx.loadingOrderButton)
                                            ? (_openBlock(), _createElementBlock("i", _hoisted_120))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 1
                                      }, 8, ["onClick", "model", "style", "loading", "disabled", "label"]))
                                    : _createCommentVNode("", true),
                                _createVNode(_component_PosInvoiceCartDialog, {
                                  ref: "cartDialog",
                                  visible: _ctx.showInvoiceCartDialog,
                                  "onUpdate:visible": _cache[28] || (_cache[28] = ($event: any) => (_ctx.showInvoiceCartDialog = $event)),
                                  selectedLineItemIndex: _ctx.selectedLineItemIndex,
                                  lineItems: _ctx.selectedInvoiceLineItems,
                                  onClosePartDialog: _ctx.closeInvoiceCartDialog,
                                  order: _ctx.getOrder[0],
                                  "onUpdate:order": _ctx.updateInvoiceForRefund,
                                  onProcessedRefund: _ctx.onProcessedRefund
                                }, null, 8, ["visible", "selectedLineItemIndex", "lineItems", "onClosePartDialog", "order", "onUpdate:order", "onProcessedRefund"])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])),
    _createVNode(_component_ConfirmDialog, {
      group: "continueTransaction",
      style: {"max-width":"40rem"}
    }, {
      message: _withCtx(({ message }) => [
        _createElementVNode("div", _hoisted_121, [
          _createElementVNode("div", {
            innerHTML: message.message
          }, null, 8, _hoisted_122),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.continueTransactionLabelItems, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.to,
              style: {"display":"flex","align-items":"center","min-height":"40px"}
            }, [
              _createElementVNode("span", null, _toDisplayString(item.label), 1),
              _createVNode(_component_router_link, {
                to: item.to,
                class: "inline-block border-none bg-white ml-1"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    onClick: message.reject,
                    class: "block"
                  }, _toDisplayString(item.message), 9, _hoisted_123)
                ]),
                _: 2
              }, 1032, ["to"]),
              (item.draft)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    style: {"margin-left":"auto"},
                    onClick: ($event: any) => {
              _ctx.saveOrder();
              (message as any).accept();
            },
                    label: "Save Draft"
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_Dialog, {
      visible: _ctx.showOrderConfirmationDialog,
      modal: "",
      class: "w-11 md:w-7 lg:w-4 xl:w-3",
      closable: false
    }, {
      header: _withCtx(() => [
        (_ctx.getSalesOrder.so_id)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_124, "Update Order"))
          : (_openBlock(), _createElementBlock("h4", _hoisted_125, "Order Creation"))
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_126, [
          _createElementVNode("div", _hoisted_127, [
            _createVNode(_component_Button, {
              label: _ctx.getSalesOrder.so_id ? 'Update Order' : 'Create Order',
              class: "w-full",
              style: {"padding":"30px"},
              icon: "pi pi-save",
              onClick: _ctx.checkAndConfirmOrder,
              loading: _ctx.loadingOrderButton
            }, null, 8, ["label", "onClick", "loading"])
          ]),
          _createElementVNode("div", _hoisted_128, [
            _createVNode(_component_Button, {
              label: "Save Order (Draft)",
              class: "w-full py-30",
              style: {"padding":"30px"},
              icon: "pi pi-save",
              onClick: _ctx.saveOrder
            }, null, 8, ["onClick"])
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_129, [
            _createVNode(_component_Button, {
              label: "Save Order (New Draft)",
              class: "w-full py-30",
              style: {"padding":"30px"},
              icon: "pi pi-save",
              onClick: _ctx.saveNewOrder
            }, null, 8, ["onClick"])
          ], 512), [
            [_vShow, _ctx.getCurrentUnsavedOrder.id]
          ]),
          _createElementVNode("div", _hoisted_130, [
            _createVNode(_component_Button, {
              label: "Exit",
              class: "w-full",
              icon: "pi pi-check",
              style: {"padding":"30px"},
              onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.showOrderConfirmationDialog = false))
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showOIPrompt,
      "onUpdate:visible": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.showOIPrompt) = $event)),
      modal: ""
    }, {
      header: _withCtx(() => _cache[76] || (_cache[76] = [
        _createTextVNode(" Other Income ")
      ])),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Submit",
          icon: "pi pi-check",
          onClick: _cache[32] || (_cache[32] = ($event: any) => (_ctx.addOIandPay()))
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_131, [
          _cache[77] || (_cache[77] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '104px' }
          }, "Note", -1)),
          _createVNode(_component_InputText, {
            modelValue: _ctx.oiNote,
            "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.oiNote) = $event)),
            id: "reg",
            class: "text-sm"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_132, [
          _cache[78] || (_cache[78] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '104px' }
          }, "Amount", -1)),
          _createVNode(_component_InputText, {
            modelValue: _ctx.oiCharge,
            "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.oiCharge) = $event)),
            id: "reg",
            class: "text-sm"
          }, null, 8, ["modelValue"])
        ]),
        _cache[79] || (_cache[79] = _createElementVNode("small", { style: {
        fontWeight: '400',
        fontStyle: 'italic',
        color: '#495057',
        maxWidth: '300px',
        display: 'block',
        wordWrap: 'break-word',
        marginTop: '8px',
      } }, " Other income is used when receiving a payment for something other than an invoice and it is not applied to a customer account. ", -1))
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showRoaModal,
      "onUpdate:visible": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.showRoaModal) = $event)),
      modal: ""
    }, {
      header: _withCtx(() => _cache[80] || (_cache[80] = [
        _createTextVNode(" Payment to Account ")
      ])),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Submit",
          icon: "pi pi-check",
          onClick: _cache[36] || (_cache[36] = ($event: any) => (_ctx.addROAandPay()))
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_133, [
          _cache[81] || (_cache[81] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '104px' }
          }, "Note", -1)),
          _createVNode(_component_InputText, {
            modelValue: _ctx.roaNote,
            "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.roaNote) = $event)),
            id: "reg",
            class: "text-sm"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_134, [
          _cache[82] || (_cache[82] = _createElementVNode("small", {
            class: "p-inputgroup-addon font-semibold general-label-color",
            style: { width: '104px' }
          }, "Amount", -1)),
          _createVNode(_component_InputText, {
            modelValue: _ctx.roaCharge,
            "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.roaCharge) = $event)),
            id: "reg",
            class: "text-sm"
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showChangeDialog,
      "onUpdate:visible": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.showChangeDialog) = $event)),
      modal: ""
    }, {
      header: _withCtx(() => _cache[83] || (_cache[83] = [
        _createTextVNode(" This Transaction Has a Negative Balance ")
      ])),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          disabled: _ctx.payoutDisabled,
          label: "Payout To Customer",
          class: "p-button-text",
          onClick: _ctx.handleMixedInvoices
        }, null, 8, ["disabled", "onClick"]),
        _createVNode(_component_Button, {
          label: "Credit to Remain on Account",
          icon: "pi pi-check",
          onClick: _ctx.handleROA
        }, null, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _cache[84] || (_cache[84] = _createElementVNode("span", null, " Are you returning the balance to the customer, or will it remain on the account? ", -1))
      ]),
      _: 1
    }, 8, ["visible"]),
    (this.isOnline && _ctx.unsavedOrders.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_135, [
          _createVNode(_component_UnsavedOrdersDialog, {
            unsavedOrders: _ctx.unsavedOrders,
            isOnline: _ctx.isOnline
          }, null, 8, ["unsavedOrders", "isOnline"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_MessageBox, {
      visible: _ctx.showLocalServiceError,
      "onUpdate:visible": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.showLocalServiceError) = $event)),
      header: "Error with Rover Web Local Service",
      icon: "WARNING",
      message: "There was an error with the Rover Local Service. Please try again or contact support.",
      primaryButton: "Retry",
      cancelButton: "Logout",
      onPrimaryButtonClick: _cache[40] || (_cache[40] = ($event: any) => (_ctx.retryRegisterFetch())),
      onCancelButtonClick: _cache[41] || (_cache[41] = ($event: any) => (_ctx.localServiceErrorLogout()))
    }, null, 8, ["visible"]),
    _createVNode(_component_MessageBox, {
      visible: _ctx.showRemoveCreditError,
      "onUpdate:visible": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.showRemoveCreditError) = $event)),
      header: "Remove One Or More Credits",
      icon: "WARNING",
      message: "One or more credits must be removed to reach a zero dollar balance.",
      primaryButton: "Return to Invoice Selection",
      onPrimaryButtonClick: _cache[43] || (_cache[43] = ($event: any) => (_ctx.returnToInvoiceSelection()))
    }, null, 8, ["visible"]),
    _createVNode(_component_MessageBox, {
      visible: _ctx.showCcOverageError,
      "onUpdate:visible": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.showCcOverageError) = $event)),
      header: "Credit Card Overage Not Allowed",
      icon: "WARNING",
      message: "A credit card overage is not allowed. The customer must do a separate ROA transaction if they wish to put money from the Credit Card on the account. Please reduce the Credit Card amount to remove the overage.",
      primaryButton: "OK",
      onPrimaryButtonClick: _cache[45] || (_cache[45] = ($event: any) => (_ctx.showCcOverageError = false))
    }, null, 8, ["visible"]),
    _createVNode(_component_MessageBox, {
      visible: _ctx.checkingRegister,
      "onUpdate:visible": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.checkingRegister) = $event)),
      header: "Loading Register",
      icon: "DESKTOP",
      message: "Determing the status of the register. Please wait..."
    }, null, 8, ["visible"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showSelectReceiptPrinter,
      header: "Select Receipt Printer",
      icon: "PRINT",
      primaryButton: "Select",
      inputType: "DROPDOWN",
      inputLabel: "Receipt Printer",
      options: _ctx.printerOptions,
      optionLabel: "name",
      optionValue: "id",
      onPrimaryButtonClick: _ctx.setReceiptPrinter
    }, null, 8, ["showDialog", "options", "onPrimaryButtonClick"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showClearOrderDialog,
      header: "Clear Order",
      icon: "WARNING",
      message: "Are you sure you want to clear the order?",
      primaryButton: "Yes",
      cancelButton: "No",
      onPrimaryButtonClick: _ctx.clearCurrentOrder,
      onCancelButtonClick: _cache[47] || (_cache[47] = ($event: any) => (_ctx.showClearOrderDialog = false))
    }, null, 8, ["showDialog", "onPrimaryButtonClick"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showClearInvoicesDialog,
      header: _ctx.dynamicClearInvoiceText,
      icon: "WARNING",
      message: _ctx.dynamicInvoiceText,
      primaryButton: "Yes",
      cancelButton: "No",
      onPrimaryButtonClick: _ctx.clearInvoicesOrder,
      onCancelButtonClick: _cache[48] || (_cache[48] = ($event: any) => (_ctx.showClearInvoicesDialog = false))
    }, null, 8, ["showDialog", "header", "message", "onPrimaryButtonClick"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showContinueToPaymentDialog,
      header: "Continue to Payment",
      icon: "QUESTION",
      message: this.continueToPaymentMessage,
      primaryButton: "Yes",
      cancelButton: "No",
      onPrimaryButtonClick: _cache[49] || (_cache[49] = ($event: any) => {
      _ctx.continueToPayment = true;
      _ctx.newInvoice = '';
    }),
      onCancelButtonClick: _cache[50] || (_cache[50] = ($event: any) => {
      _ctx.showContinueToPaymentDialog = false;
      _ctx.loadingOrderButton = false;
      _ctx.$router.push('/pos/customers');
    })
    }, null, 8, ["showDialog", "message"]),
    _createVNode(_component_AddShippingAddressDialog, {
      header: "Use New Address",
      showDialog: _ctx.showAddAddressDialog,
      addAddressToCustomerSwitch: _ctx.customerModuleAccess,
      customerId: _ctx.getCustomer?.cust_id,
      onCloseDialog: _ctx.closeAddAddressDialog,
      onSaveAddress: _ctx.saveNewAddress
    }, null, 8, ["showDialog", "addAddressToCustomerSwitch", "customerId", "onCloseDialog", "onSaveAddress"]),
    _createVNode(_component_AuthenticateDialog, {
      title: "Enter User Credentials",
      visible: _ctx.showEntryUserLogin,
      requireAdminAccess: false,
      onOnAuthenticate: _ctx.onEntryUserAuthenticate
    }, null, 8, ["visible", "onOnAuthenticate"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showShipSignatureDialog,
      "onUpdate:visible": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.showShipSignatureDialog) = $event)),
      modal: "",
      header: "Signature Required",
      style: { width: '80vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Signature, {
          signature: _ctx.getShipSignature.signature,
          signatureType: _ctx.getShipSignature.signature_type,
          receivedBy: _ctx.getShipSignature.signature_by,
          receivedDate: _ctx.getShipSignature.signature_date,
          receivedDateTime: _ctx.getShipSignature.signature_time,
          onSave: _ctx.saveSignature,
          onClear: _ctx.clearShipSignature
        }, null, 8, ["signature", "signatureType", "receivedBy", "receivedDate", "receivedDateTime", "onSave", "onClear"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}