
import { useVuelidate } from "@vuelidate/core";
import { defineComponent, inject } from "vue";
import CollapsibleSection from "../UI/CollapsibleSection.vue";
import Textarea from "primevue/textarea";
import TaxCodes from "../Sales/Orders/TaxCodes.vue";
import Button from "primevue/button";
import DropDown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import { mapActions, mapGetters, mapState } from "vuex";
import Utils from "@/utility/utils";
import { Field } from "@/types/fdict";
import { FDICT_SO } from "@/utility/fdicts/so";
import Dialog from "primevue/dialog";
import Signature from "../UI/Signature.vue";
import { helpers, required } from "@vuelidate/validators";
import RoverInput from "@/components/UI/RoverInput.vue";
export default defineComponent({
  components: {
    Button,
    Calendar,
    CollapsibleSection,
    Dialog,
    DropDown,
    Signature,
    TaxCodes,
    Textarea,
    RoverInput,
  },
  created() {
    // Check if the order is a draft order before setting defaults
    if (!this.getCurrentUnsavedOrder.id) {
      !this.getSalesOrder.type && this.setDefaultSOTypeSelection();
      this.setDefaultSalesRepSelection();
    }
  },
  mounted() {
    this.updateIsOrderHeaderValid();
  },
  setup() {
    const invalidSubmit = inject<boolean>("invalidSubmit");
    return { v$: useVuelidate(), invalidSubmit };
  },
  validations() {
    const customFieldValidations = this.editableFields.reduce(
      (acc: any, field: any) => {
        if (
          Utils.booleanCheck(field.required) &&
          Utils.booleanCheck(field.custom_field)
        ) {
          acc[field.json_name] = {
            required: helpers.withMessage("* Required", required),
            $autoDirty: true,
          };
        }
        return acc;
      },
      {},
    );
    const fieldValidations = {} as any;
    this.editableFields
      .filter(
        (field) =>
          Utils.booleanCheck(field.required) &&
          !Utils.booleanCheck(field.custom_field),
      )
      .forEach((field) => {
        fieldValidations[field.json_name] = {
          required: helpers.withMessage(
            `${field.display_name} Required`,
            required,
          ),
          $autoDirty: true,
        };
      });
    return {
      getSalesOrder: {
        date: {
          required: helpers.withMessage("Please Enter a Date", required),
          $autoDirty: true,
        },
        type: {
          required: helpers.withMessage("Please Enter a Type", required),
          $autoDirty: true,
        },
        signature: {
          required: helpers.withMessage("Please add a signature", required),
          $autoDirty: true,
        },
        pos_order_code: {
          required: helpers.withMessage("Please Enter a Type", required),
          $autoDirty: true,
        },
        custom_fields: customFieldValidations,
        ...fieldValidations,
      },
    };
  },
  emits: ["update:isOrderHeaderValid"],
  data() {
    return {
      status: [
        {
          description: "Closed",
          value: "C",
        },
        {
          description: "New",
          value: "N",
        },
        {
          description: "Backorder",
          value: "B",
        },
      ],
      sectionsStatus: {
        extraInformation: true,
        additionalOrderInfo: true,
      },
      signatureRequired: false,
      visibleSignatureDialog: false,
    };
  },
  computed: {
    ...mapState(["pos", "session"]),
    ...mapGetters({
      getRegister: "pos/getRegister",
      getCustomer: "pos/getCustomer",
      showPosNotes: "mrkControl/showPosNotes",
      posOrderCodes: "mrkControl/posOrderCodes",
      salesOrderTypes: "mrkControl/salesOrderTypes",
      customFDictFields: "fdict/customFields",
      getSalesOrder: "pos/getSalesOrder",
      posShowPayTerms: "mrkControl/posShowPayTerms",
      posShowTaxCodes: "mrkControl/posShowTaxCodes",
      posShowBookDate: "mrkControl/posShowBookDate",
      posShowShipVia: "mrkControl/posShowShipVia",
      posShowFreightAmount: "mrkControl/posShowFreightAmount",
      posShowSalesRep: "mrkControl/posShowSalesRep",
      getTerms: "terms/getTerms",
      shipViaItems: "shipControl/getShipViaItems",
      posShowOrderStatus: "mrkControl/posShowOrderStatus",
      posExtraInfoItems: "mrkControl/posExtraInfoItems",
      getFdictFields: "fdict/getFdictFields",
      getFieldLabel: "fdict/getFieldLabel",
      getReps: "rep/getReps",
      getCurrentUnsavedOrder: "pos/getCurrentUnsavedOrder",
      getOldSalesOrder: "pos/getOldSalesOrder",
      getField: "fdict/getField",
    }),
    termsCodeField(): Field {
      return this.getField("SO", FDICT_SO.TERMS_CODE);
    },
    poNumberField(): Field {
      return this.getField("SO", FDICT_SO.PO_NUMBER);
    },
    shipViaField(): Field {
      return this.getField("SO", FDICT_SO.SHIP_VIA);
    },
    freightAmountField(): Field {
      return this.getField("SO", FDICT_SO.FREIGHT_AMOUNT);
    },
    bookDateLabel(): string {
      return this.getFieldLabel("SO", FDICT_SO.BOOK_DATE, "Book Date");
    },
    termsCodeLabel(): string {
      return this.getFieldLabel("SO", FDICT_SO.TERMS_CODE, "Pay Terms");
    },
    editableFields(): Field[] {
      const posOrderFields = this.posExtraInfoItems
        .filter((data: any) => data.pos_extra_info_read_only != "Y")
        .map((data: any) => data.pos_extra_info);
      if (posOrderFields.length === 0) {
        return this.customFDictFields("SO");
      } else {
        return this.getFdictFields("SO").filter((field: Field) =>
          posOrderFields.includes(field.field_no),
        );
      }
    },
    hasPosOrderCodes(): boolean {
      return this.posOrderCodes && this.posOrderCodes.length > 0;
    },
    reps(): any {
      return this.getReps.map((rep: any) => ({
        rep: rep.rep_id,
        rep_name: rep.name,
      }));
    },
    shipDateLabel(): string {
      return this.getFieldLabel("SO", FDICT_SO.DATE, "Ship Date");
    },
  },
  methods: {
    ...mapActions({
      validateSalesOrder: "pos/validateSalesOrder",
    }),
    isCustomFieldsValid() {
      let invalidCustomFields = true;
      this.editableFields?.forEach((field: Field) => {
        const required = Utils.booleanCheck(field.required);
        const isCustomField = Utils.booleanCheck(field.custom_field);
        if (
          required &&
          isCustomField &&
          (this.v$.getSalesOrder as any).custom_fields[
            field.json_name as string
          ]?.required?.$invalid
        ) {
          invalidCustomFields = false;
          return;
        }
      });
      return invalidCustomFields;
    },
    isFieldInvalid(field: Field): boolean {
      const validate = this.v$.getSalesOrder as any;
      if (Utils.booleanCheck(field.custom_field)) {
        return validate.custom_fields[field.json_name]?.required?.$invalid;
      } else {
        return validate[field.json_name]?.required?.$invalid;
      }
    },
    invalidFieldMessage(field: Field): string {
      const validate = this.v$.getSalesOrder as any;
      if (Utils.booleanCheck(field.custom_field)) {
        return validate.custom_fields[field.json_name]?.required?.$message;
      } else {
        return validate[field.json_name]?.required?.$message;
      }
    },
    isOrderDateInvalid(): boolean {
      return (
        this.v$.getSalesOrder &&
        (this.v$.getSalesOrder as any).date.$invalid &&
        !(this.v$.getSalesOrder as any).date.$dirty
      );
    },
    isOrderSignatureInvalid(): boolean {
      if (this.signatureRequired) {
        return (
          this.v$.getSalesOrder &&
          (this.v$.getSalesOrder as any).signature.$invalid &&
          !(this.v$.getSalesOrder as any).signature.$dirty
        );
      } else {
        return false;
      }
    },
    isOrderTypeInvalid(): boolean {
      return (
        this.v$.getSalesOrder &&
        (this.v$.getSalesOrder as any).type.$invalid &&
        !(this.v$.getSalesOrder as any).type.$dirty
      );
    },
    isPosOrderCodeInvalid(): boolean {
      return (this.v$.getSalesOrder && (this.v$.getSalesOrder as any))
        .pos_order_code.$invalid;
    },
    isShippingValid() {
      let invalidPOType = this.isOrderTypeInvalid();
      let invalidshipDate = this.isOrderDateInvalid();
      let invalidPosType = this.isPosOrderCodeInvalid();
      let invalidSignature = this.isOrderSignatureInvalid();

      let validType = false;
      if (this.hasPosOrderCodes) {
        validType = !invalidPosType;
      } else {
        validType = !invalidPOType;
      }

      if (this.$route.fullPath === "/pos/shipping") {
        return validType && !invalidshipDate && !invalidSignature;
      } else {
        return true;
      }
    },
    saveSignature(data: any) {
      this.getSalesOrder.signature = data.signature;
      this.getSalesOrder.signature_type = data.signatureType;
      this.getSalesOrder.signature_by = data.receivedBy;
      this.getSalesOrder.signature_date = Utils.formatDate(data.receivedDate);
      this.getSalesOrder.signature_time = data.receivedTime;
      this.visibleSignatureDialog = false;
    },
    selectBookDate(data: any) {
      this.getSalesOrder.book_date = Utils.formatDate(data);
    },
    selectShipDate(data: any) {
      this.getSalesOrder.date = Utils.formatDate(data);
    },
    setDefaultSalesRepSelection() {
      // if POSShowSalesRep flag is set and sales rep is not set, set the default sales rep
      if (this.posShowSalesRep && !this.getSalesOrder.rep) {
        this.getSalesOrder.rep = this.getCustomer?.rep_items?.[0]?.rep;
      }
    },
    setDefaultSOTypeSelection() {
      const defaultItem = this.posOrderCodes.find(
        (item: { default_selection: string }) => item.default_selection === "Y",
      );
      if (defaultItem) {
        this.getSalesOrder.type = defaultItem.so_type_code;
        this.getSalesOrder.pos_order_code = defaultItem.pos_order_code;
        this.getSalesOrder.ship_via = defaultItem.ship_via_code;
        this.signatureRequired = defaultItem.signature_required === "Y";
      }
    },
    showSignatureDialog() {
      this.visibleSignatureDialog = true;
    },
    toggleSectionIcon(tab: "extraInformation" | "additionalOrderInfo") {
      this.sectionsStatus[tab] = !this.sectionsStatus[tab];
    },
    updatePosType(newTypeCode: string, oldTypeCode: string) {
      const newType = this.posOrderCodes.find(
        (item: any) => item.pos_order_code === newTypeCode,
      );
      const oldType = this.posOrderCodes.find(
        (item: any) => item.pos_order_code === oldTypeCode,
      );
      if (newType) {
        this.signatureRequired = newType.signature_required === "Y";
        this.getSalesOrder.type = newType.so_type_code;
        this.getSalesOrder.pos_order_code = newType.pos_order_code;
        if (
          !oldType ||
          !this.getSalesOrder.ship_via ||
          (oldType && this.getSalesOrder.ship_via === oldType.ship_via_code)
        ) {
          this.getSalesOrder.ship_via = newType.ship_via_code;
        }
      } else {
        this.signatureRequired = oldType.signature_required === "Y";
        this.getSalesOrder.type = oldType.so_type_code;
        this.getSalesOrder.pos_order_code = oldType.pos_order_code;
        this.getSalesOrder.ship_via = oldType.ship_via_code;
      }
    },
    updateTaxCodes(taxCodes: any) {
      this.getSalesOrder.tax_codes_items = taxCodes;
    },
    handleValidateInput(field: Field) {
      this.validateSalesOrder({
        oldSalesOrder: this.getOldSalesOrder,
        metaData: {
          validate_only: "Y",
          validate_reason: field.dict_name,
          validate_field_no: field.field_no,
        },
      });
    },
    updateField(data: any, field: Field) {
      this.getSalesOrder[field.json_name] = data;
      if (field.custom_field) {
        this.getSalesOrder.custom_fields[field.json_name] = data;
      }
    },
    updateIsOrderHeaderValid() {
      this.$emit(
        "update:isOrderHeaderValid",
        this.isShippingValid() && this.isCustomFieldsValid(),
      );
    },
  },
  watch: {
    getSalesOrder: {
      deep: true,
      handler() {
        this.updateIsOrderHeaderValid();
      },
    },
    "getSalesOrder.pos_order_code": {
      handler: function (newVal, oldVal) {
        this.updatePosType(newVal, oldVal);
      },
    },
  },
});
