import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "grid p-0 m-0 mt-2 justify-content-left pl-1 pr-2" }
const _hoisted_3 = {
  key: 0,
  class: "grid p-1 m-0 col-12 md:col-6 xl:col-4"
}
const _hoisted_4 = { class: "p-inputgroup" }
const _hoisted_5 = { class: "grid p-1 m-0 col-12 md:col-6 xl:col-4" }
const _hoisted_6 = { class: "p-inputgroup" }
const _hoisted_7 = {
  key: 1,
  class: "grid p-1 m-0 col-12 md:col-6 xl:col-4"
}
const _hoisted_8 = { class: "p-inputgroup" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "p-error" }
const _hoisted_11 = {
  key: 2,
  class: "grid p-1 m-0 col-12 md:col-6 xl:col-4"
}
const _hoisted_12 = { class: "p-inputgroup" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "p-error" }
const _hoisted_15 = { class: "grid p-1 m-0 col-12 md:col-6 xl:col-4" }
const _hoisted_16 = { class: "p-inputgroup" }
const _hoisted_17 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '112px' }
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "p-error" }
const _hoisted_20 = {
  key: 3,
  class: "grid p-1 m-0 col-12 md:col-6 xl:col-4"
}
const _hoisted_21 = { class: "p-inputgroup" }
const _hoisted_22 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '112px' }
}
const _hoisted_23 = {
  key: 4,
  class: "grid p-1 m-0 col-12 md:col-6 xl:col-4"
}
const _hoisted_24 = { class: "p-inputgroup" }
const _hoisted_25 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '112px' }
}
const _hoisted_26 = {
  key: 5,
  class: "grid p-1 m-0 col-12 md:col-6 xl:col-4"
}
const _hoisted_27 = { class: "p-inputgroup" }
const _hoisted_28 = {
  key: 6,
  class: "grid p-1 m-0 col-12 md:col-6 xl:col-4"
}
const _hoisted_29 = { class: "p-inputgroup" }
const _hoisted_30 = {
  key: 7,
  class: "grid p-1 m-0 col-12 md:col-6 xl:col-4"
}
const _hoisted_31 = { class: "p-inputgroup" }
const _hoisted_32 = { class: "p-inputgroup" }
const _hoisted_33 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '112px' }
}
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { class: "p-error" }
const _hoisted_36 = { class: "grid p-1 m-0 pl-2 col-12" }
const _hoisted_37 = {
  key: 0,
  style: {"display":"grid","align-content":"center","margin-left":"2px"}
}
const _hoisted_38 = { class: "p-error" }
const _hoisted_39 = {
  key: 0,
  class: "grid p-1 m-0 pl-2 col-6"
}
const _hoisted_40 = {
  key: 1,
  class: "grid p-1 m-0 pl-2 col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropDown = _resolveComponent("DropDown")!
  const _component_RoverInput = _resolveComponent("RoverInput")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TaxCodes = _resolveComponent("TaxCodes")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!
  const _component_Signature = _resolveComponent("Signature")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CollapsibleSection, {
        isOpen: _ctx.sectionsStatus.extraInformation,
        header: "Order Information",
        "onUpdate:isOpen": _cache[16] || (_cache[16] = ($event: any) => (_ctx.toggleSectionIcon('extraInformation')))
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.posShowOrderStatus)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _cache[18] || (_cache[18] = _createElementVNode("small", {
                      class: "p-inputgroup-addon font-semibold general-label-color",
                      style: { width: '112px' }
                    }, "Status", -1)),
                    _createVNode(_component_DropDown, {
                      modelValue: _ctx.getSalesOrder.status,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getSalesOrder.status) = $event)),
                      options: _ctx.status,
                      optionLabel: "description",
                      optionValue: "value"
                    }, null, 8, ["modelValue", "options"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[19] || (_cache[19] = _createElementVNode("small", {
                  class: "p-inputgroup-addon font-semibold general-label-color",
                  style: { width: '150px' }
                }, " PO Number ", -1)),
                _createVNode(_component_RoverInput, {
                  modelValue: _ctx.getSalesOrder.po_number,
                  "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getSalesOrder.po_number) = $event)),
                    _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateField($event, _ctx.poNumberField)))
                  ],
                  field: _ctx.poNumberField,
                  onValidateInput: _ctx.handleValidateInput
                }, null, 8, ["modelValue", "field", "onValidateInput"])
              ])
            ]),
            (_ctx.hasPosOrderCodes)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _cache[20] || (_cache[20] = _createElementVNode("small", {
                      class: "p-inputgroup-addon font-semibold general-label-color",
                      style: { width: '112px' }
                    }, "Type", -1)),
                    _createVNode(_component_DropDown, {
                      modelValue: _ctx.getSalesOrder.pos_order_code,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.getSalesOrder.pos_order_code) = $event)),
                      options: _ctx.posOrderCodes,
                      optionLabel: "pos_order_desc",
                      optionValue: "pos_order_code",
                      placeholder: "Select Type",
                      class: _normalizeClass({
                  'p-invalid':
                    (_ctx.v$.getSalesOrder.pos_order_code.required.$invalid &&
                      _ctx.v$.getSalesOrder.pos_order_code.$dirty) ||
                    (_ctx.invalidSubmit &&
                      _ctx.v$.getSalesOrder.pos_order_code.required.$invalid),
                })
                    }, null, 8, ["modelValue", "options", "class"])
                  ]),
                  (
                (_ctx.v$.getSalesOrder.pos_order_code.required.$invalid &&
                  _ctx.v$.getSalesOrder.pos_order_code.$dirty) ||
                (_ctx.invalidSubmit &&
                  _ctx.v$.getSalesOrder.pos_order_code.required.$invalid)
              )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("small", _hoisted_10, _toDisplayString(_ctx.v$.getSalesOrder.pos_order_code.required.$message), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _cache[21] || (_cache[21] = _createElementVNode("small", {
                      class: "p-inputgroup-addon font-semibold general-label-color",
                      style: { width: '112px' }
                    }, "Order Type", -1)),
                    _createVNode(_component_DropDown, {
                      modelValue: _ctx.getSalesOrder.type,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.getSalesOrder.type) = $event)),
                      options: _ctx.salesOrderTypes,
                      optionLabel: "so_type_code_description",
                      optionValue: "valid_so_type",
                      placeholder: "Select Order Type",
                      class: _normalizeClass({
                  'p-invalid':
                    _ctx.isOrderTypeInvalid() ||
                    (_ctx.invalidSubmit && _ctx.v$.getSalesOrder.type.required.$invalid),
                })
                    }, null, 8, ["modelValue", "options", "class"])
                  ]),
                  (
                _ctx.isOrderTypeInvalid() ||
                (_ctx.invalidSubmit && _ctx.v$.getSalesOrder.type.required.$invalid)
              )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("small", _hoisted_14, _toDisplayString(_ctx.v$.getSalesOrder.type.required.$message), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("small", _hoisted_17, _toDisplayString(_ctx.shipDateLabel), 1),
                _createVNode(_component_Calendar, {
                  modelValue: _ctx.getSalesOrder.date,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.getSalesOrder.date) = $event)),
                  showIcon: true,
                  dateFormat: "mm-dd-yy",
                  onDateSelect: _ctx.selectShipDate,
                  class: _normalizeClass({
                  'p-invalid':
                    _ctx.isOrderDateInvalid() ||
                    (_ctx.invalidSubmit && _ctx.v$.getSalesOrder.date.required.$invalid),
                })
                }, null, 8, ["modelValue", "onDateSelect", "class"])
              ]),
              (
                _ctx.isOrderDateInvalid() ||
                (_ctx.invalidSubmit && _ctx.v$.getSalesOrder.date.required.$invalid)
              )
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createElementVNode("small", _hoisted_19, _toDisplayString(_ctx.v$.getSalesOrder.date.required.$message), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.posShowBookDate)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("small", _hoisted_22, _toDisplayString(_ctx.bookDateLabel), 1),
                    _createVNode(_component_Calendar, {
                      modelValue: _ctx.getSalesOrder.book_date,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.getSalesOrder.book_date) = $event)),
                      showIcon: true,
                      dateFormat: "mm-dd-yy",
                      onDateSelect: _ctx.selectBookDate
                    }, null, 8, ["modelValue", "onDateSelect"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.posShowPayTerms)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("small", _hoisted_25, _toDisplayString(_ctx.termsCodeLabel), 1),
                    _createVNode(_component_RoverInput, {
                      field: _ctx.termsCodeField,
                      modelValue: _ctx.getSalesOrder.terms_code,
                      "onUpdate:modelValue": [
                        _cache[7] || (_cache[7] = ($event: any) => ((_ctx.getSalesOrder.terms_code) = $event)),
                        _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateField($event, _ctx.termsCodeField)))
                      ],
                      options: _ctx.getTerms,
                      optionLabel: "desc",
                      optionValue: "terms_id",
                      onValidateInput: _ctx.handleValidateInput
                    }, null, 8, ["field", "modelValue", "options", "onValidateInput"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.posShowShipVia)
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                  _createElementVNode("div", _hoisted_27, [
                    _cache[22] || (_cache[22] = _createElementVNode("small", {
                      class: "p-inputgroup-addon font-semibold general-label-color",
                      style: { width: '112px' }
                    }, "Ship Via", -1)),
                    _createVNode(_component_RoverInput, {
                      field: _ctx.shipViaField,
                      modelValue: _ctx.getSalesOrder.ship_via,
                      "onUpdate:modelValue": [
                        _cache[9] || (_cache[9] = ($event: any) => ((_ctx.getSalesOrder.ship_via) = $event)),
                        _cache[10] || (_cache[10] = ($event: any) => (_ctx.updateField($event, _ctx.shipViaField)))
                      ],
                      options: _ctx.shipViaItems,
                      optionValue: "ship_via",
                      optionLabel: "ship_via",
                      onValidateInput: _ctx.handleValidateInput
                    }, null, 8, ["field", "modelValue", "options", "onValidateInput"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.posShowFreightAmount)
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, [
                    _cache[23] || (_cache[23] = _createElementVNode("small", {
                      class: "p-inputgroup-addon font-semibold general-label-color",
                      style: { width: '112px' }
                    }, " Freight Amount ", -1)),
                    _createVNode(_component_RoverInput, {
                      field: _ctx.freightAmountField,
                      modelValue: _ctx.getSalesOrder.freight_amount,
                      "onUpdate:modelValue": [
                        _cache[11] || (_cache[11] = ($event: any) => ((_ctx.getSalesOrder.freight_amount) = $event)),
                        _cache[12] || (_cache[12] = ($event: any) => (_ctx.updateField($event, _ctx.freightAmountField)))
                      ],
                      onValidateInput: _ctx.handleValidateInput
                    }, null, 8, ["field", "modelValue", "onValidateInput"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.posShowSalesRep)
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _cache[24] || (_cache[24] = _createElementVNode("small", {
                      class: "p-inputgroup-addon font-semibold general-label-color",
                      style: { width: '112px' }
                    }, "Sales Rep", -1)),
                    _createVNode(_component_DropDown, {
                      modelValue: _ctx.getSalesOrder.rep,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.getSalesOrder.rep) = $event)),
                      options: _ctx.reps,
                      optionLabel: "rep_name",
                      optionValue: "rep",
                      filter: ""
                    }, null, 8, ["modelValue", "options"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editableFields, (field) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "grid p-1 m-0 col-12 md:col-6 xl:col-4",
                key: field.field_no
              }, [
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("small", _hoisted_33, _toDisplayString(field.display_name), 1),
                  (field.custom_field)
                    ? (_openBlock(), _createBlock(_component_RoverInput, {
                        key: 0,
                        field: field,
                        modelValue: _ctx.getSalesOrder.custom_fields[field.json_name],
                        "onUpdate:modelValue": [($event: any) => ((_ctx.getSalesOrder.custom_fields[field.json_name]) = $event), ($event: any) => (_ctx.updateField($event, field))],
                        "invalid-input": _ctx.isFieldInvalid(field),
                        onValidateInput: _ctx.handleValidateInput
                      }, null, 8, ["field", "modelValue", "onUpdate:modelValue", "invalid-input", "onValidateInput"]))
                    : (_openBlock(), _createBlock(_component_RoverInput, {
                        key: 1,
                        field: field,
                        modelValue: _ctx.getSalesOrder[field.json_name],
                        "onUpdate:modelValue": [($event: any) => ((_ctx.getSalesOrder[field.json_name]) = $event), ($event: any) => (_ctx.updateField($event, field))],
                        "invalid-input": _ctx.isFieldInvalid(field),
                        onValidateInput: _ctx.handleValidateInput
                      }, null, 8, ["field", "modelValue", "onUpdate:modelValue", "invalid-input", "onValidateInput"]))
                ]),
                (_ctx.isFieldInvalid(field))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                      _createElementVNode("small", _hoisted_35, "   " + _toDisplayString(_ctx.invalidFieldMessage(field)), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_36, [
            _createVNode(_component_Button, {
              onClick: _cache[14] || (_cache[14] = () => _ctx.showSignatureDialog())
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getSalesOrder.signature ? "View Signature" : "Add Signature"), 1)
              ]),
              _: 1
            }),
            (
              _ctx.isOrderSignatureInvalid() ||
              (_ctx.invalidSubmit && _ctx.v$.getSalesOrder.signature.required.$invalid)
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                  _createElementVNode("small", _hoisted_38, _toDisplayString(_ctx.v$.getSalesOrder.signature.required.$message), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.posShowTaxCodes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                _createVNode(_component_TaxCodes, {
                  taxCodesItems: _ctx.getSalesOrder.tax_codes_items,
                  onUpdateTaxCodes: _ctx.updateTaxCodes
                }, null, 8, ["taxCodesItems", "onUpdateTaxCodes"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.showPosNotes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                _cache[25] || (_cache[25] = _createElementVNode("h4", null, "Notes", -1)),
                _createVNode(_component_Textarea, {
                  modelValue: _ctx.getSalesOrder.notes,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.getSalesOrder.notes) = $event)),
                  "aria-label": "Notes",
                  rows: "5",
                  cols: "30"
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["isOpen"])
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.visibleSignatureDialog,
      "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.visibleSignatureDialog) = $event)),
      modal: "",
      header: "Signature",
      style: { width: '80vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Signature, {
          signature: _ctx.getSalesOrder.signature,
          signatureType: _ctx.getSalesOrder.signature_type,
          receivedBy: _ctx.getSalesOrder.signature_by,
          receivedDate: _ctx.getSalesOrder.signature_date,
          receivedDateTime: _ctx.getSalesOrder.signature_time,
          onSave: _ctx.saveSignature
        }, null, 8, ["signature", "signatureType", "receivedBy", "receivedDate", "receivedDateTime", "onSave"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}