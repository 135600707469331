
import { defineComponent } from "vue";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Button from "primevue/button";
import Card from "primevue/card";
import { mapGetters, mapActions } from "vuex";
import Utils from "@/utility/utils";
import { cloneDeep } from "lodash";
import SalesOpportunityForm from "../Sales/SalesOpportunityForm.vue";
import SalesOppsDataWrapper from "../Sales/SalesOppsDataWrapper.vue";
import Dialog from "primevue/dialog";
import Customer from "@/types/customer";

export default defineComponent({
  components: {
    TabPanel,
    TabView,
    Button,
    SalesOpportunityForm,
    SalesOppsDataWrapper,
    Card,
    Dialog,
  },
  props: {
    customer: Object as () => Customer,
    defaultRoute: String,
  },
  data() {
    return {
      isResultsListView: true,
      lastUpdated: Date.now(),
      index: 0,
      indexToDelete: -1,
      opportunityChanges: {} as any,
    };
  },
  computed: {
    ...mapGetters({
      getOpportunities: "pos/getOpportunities",
      getUserId: "session/getUserId",
    }),
  },
  methods: {
    ...mapActions({
      addOpportunity: "pos/addOpportunity",
      updateOpportunity: "pos/updateOpportunity",
      removeOpportunity: "pos/removeOpportunity",
    }),
    formatTabId(tab: any) {
      const id = tab.id;
      return id.includes("New") ? `#${id.split("-")[1]}` : `#${id}`;
    },

    handleOpportunityChanges(newOpp: any, oldOpp: any) {
      this.opportunityChanges[newOpp.id as string] = Utils.compareSalesOpp(
        newOpp,
        oldOpp,
      );
    },
    async handleRemoveSaleOpp(index: number) {
      const opp = this.getOpportunities[index];
      if (!this.opportunityChanges[opp.record.id]) {
        this.indexToDelete = index;
        return;
      }
      await this.handleRemoveIndex(index);
    },
    async handleRemoveIndex(index: number) {
      this.removeOpportunity(index);
      await this.$nextTick();
      if (this.getOpportunities.length === 0) {
        this.index = 0;
      } else {
        this.index = 1;
      }
    },
    handleOpportunity(event: any, index: number) {
      this.updateOpportunity({ index, value: event });
    },
    isDataUpdated(event: number) {
      this.lastUpdated = event;
    },
    printExportData() {
      (this.$refs as any).salesOppsTable.printTable();
    },
    clearFilters() {
      (this.$refs as any).salesOppsTable.clearAllFiltersAndFetch();
    },
    handleNewSalesOpp() {
      const newCount =
        this.getOpportunities.filter((opp: any) =>
          opp.record.id.includes("New"),
        ).length + 1;
      this.addOpportunity({
        record: {
          id: "New Opp-" + newCount,
          date: Utils.formatDate(new Date()),
          assigned_to: this.getUserId,
        },
      });
      this.index = 1;
    },
    handleRowClick(event: any) {
      const index = this.getOpportunities.findIndex(
        (opp: any) => opp.record.id === event.data.id,
      );
      if (index > -1) {
        this.index = index + 1;
      } else {
        this.addOpportunity({
          record: event.data,
          old_record: cloneDeep(event.data),
        });
        this.index = 1;
      }
    },
  },
});
